import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import MediaCard from "../MediaCard";
import VideoCard from "../VideoCard";
import SourceFaceCard from "../SourceFaceCard";
import { FaceswapProjectTab } from "../..";
import { isImageUrl } from "../../../../lib/isImageUrl";
import { getFaceswap } from "../../../../redux/reducers/faceswapReducer";
import { clearFaceswap, getFaceswapListServer, getFaceswapServer } from "../../../../redux/actions/faceswapActions";

const FaceSwapScreen = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const faceswap = useSelector(getFaceswap);

  const [activeMediaTabInFaceswap, setActiveMediaTabInFaceswap] = useState("Image");

  const fetchData = (isLoading?: boolean) => dispatch(getFaceswapListServer({ isLoading }));

  useEffect(() => {
    fetchData(true);

    const intervalId = setInterval(() => fetchData(false), 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    dispatch(clearFaceswap());

    if (id) dispatch(getFaceswapServer({ id: parseInt(id), isProjectRestored: true }));
  }, [id]);

  useEffect(() => {
    if (faceswap) {
      let currentTab = faceswap.faceswapType.charAt(0).toUpperCase() + faceswap.faceswapType.slice(1);

      if (!currentTab || !faceswap.faceswapType) {
        currentTab = faceswap.target ? (isImageUrl(faceswap.target) ? "Image" : "Video") : "Image";
      }

      setActiveMediaTabInFaceswap(currentTab);
    }
  }, [faceswap, faceswap?.faceswapType]);

  return (
    <>
      <SourceFaceCard cardTitle="Face" type={FaceswapProjectTab.Faceswap} tooltipType="image" />
      <MediaCard
        type={FaceswapProjectTab.Faceswap}
        cardTitle="Media"
        activeTab={activeMediaTabInFaceswap}
        setActiveTab={setActiveMediaTabInFaceswap}
      />
      <VideoCard
        type={FaceswapProjectTab.Faceswap}
        generateButtonText="Swap Face Now"
        activeTab={activeMediaTabInFaceswap}
      />
    </>
  );
};

export default FaceSwapScreen;
