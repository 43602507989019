import Select, { components } from "react-select";
import styled, { useTheme } from "styled-components";
import { FC, ReactNode, useState } from "react";

export interface CustomSelectOptions {
  borderRadius: string;
  borderRadiusList: string;
  border: string;
  borderHover: string;
  backgroundColor: string;
  textColor: string;
  textColorActive: string;
}

interface OptionType {
  value: string;
  label: string;
  icon?: string;
  supports_formality?: boolean;
  endAddornment?: ReactNode;
}

interface Props {
  optionsList: OptionType[];
  value: string | number;
  onChange: (value: string | number) => void;
  disabled?: boolean;
  placeholder?: string;
  actionIcon?: ReactNode;
  customOptions?: CustomSelectOptions;
}

const SelectVideoTranslation: FC<Props> = ({
  optionsList,
  onChange,
  value,
  disabled,
  placeholder,
  actionIcon,
  customOptions,
}) => {
  const theme: any = useTheme();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  let options: CustomSelectOptions;

  customOptions
    ? (options = {
        ...customOptions,
      })
    : (options = {
        borderRadius: "8px",
        borderRadiusList: "12px",
        border: theme.videoTranslationBorder,
        borderHover: theme.videoTranslationBorder,
        backgroundColor: theme.videoTranslationBackground,
        textColor: theme.primaryText,
        textColorActive: theme.activeMenu,
      });

  const customStyles = {
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 1000,
    }),
    control: (provided: any) => ({
      ...provided,
      borderRadius: options.borderRadius,
      boxShadow: "none",
      border: options.border,
      backgroundColor: options.backgroundColor,
      height: "48px",
      cursor: "pointer",

      "&:hover": {
        border: options.borderHover,
      },
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      paddingLeft: "16px",
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      paddingRight: "16px",

      div: {
        padding: 0,
      },
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      svg: {
        transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.3s",
        path: {
          stroke: theme.icon,
        },
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow: "none",
      borderRadius: options.borderRadiusList,
    }),
    menuList: (provided: any) => ({
      ...provided,
      borderRadius: options.borderRadiusList,
      maxHeight: "125px",
      border: options.border,
      backgroundColor: options.backgroundColor,
      boxShadow: "none",
      paddingBottom: "2px",
      paddingTop: "2px",

      "@media (max-width: 1001px)": {
        "::-webkit-scrollbar": {
          display: "none",
        },
      },

      "::-webkit-scrollbar": {
        width: "2px",
      },

      "::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: options.textColorActive,
      },

      "::-webkit-scrollbar-track": {
        margin: "15px 0",
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      color: options.textColor,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      color: options.textColor,
      opacity: 0.6,
    }),
    input: (provided: any) => ({
      ...provided,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      color: options.textColor,
    }),
    option: (provided: any) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
      backgroundColor: "transparent",
      padding: "8px 16px",

      "&:active": {
        backgroundColor: "transparent",
        color: options.textColorActive,
      },
    }),
  };

  const formatOptionLabel = (option: OptionType, { context }: { context: string }) => (
    <SelectorOptionItem active={context === "menu" && option.value === value} options={options}>
      <Row>
        {option?.icon && <Icon src={option.icon} />}
        {option.label}
        {context === "menu" && option.value === value && option?.endAddornment}
      </Row>
    </SelectorOptionItem>
  );

  const handleChange = (selectedOption: OptionType | null) => {
    if (selectedOption) {
      onChange(selectedOption.value);
    }
  };

  const DropdownIndicator = (props: any) =>
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>{actionIcon}</components.DropdownIndicator>
    );

  return (
    <Select
      value={optionsList.find((option) => option.value === value) || null}
      onChange={handleChange}
      options={optionsList}
      isDisabled={disabled}
      styles={customStyles}
      isSearchable={false}
      placeholder={placeholder}
      menuPlacement="auto"
      menuShouldBlockScroll={true}
      menuPortalTarget={document.body}
      formatOptionLabel={formatOptionLabel}
      components={{ DropdownIndicator }}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
    />
  );
};

const SelectorOptionItem = styled.span<{ active?: boolean; options: CustomSelectOptions }>`
  position: relative;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ options }) => options.textColor};
  cursor: pointer;
  display: block !important;
  border: none !important;
  margin: 0 !important;
  ${({ active, theme, options }) =>
    active &&
    `
       border-radius: ${options.borderRadiusList};
       color: ${theme.activeMenu};
  `}
`;

const Row = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

const Icon = styled.img`
  height: 15px;
  width: 20px;
  border-radius: 4px !important;
  object-fit: fill;
`;

export default SelectVideoTranslation;
