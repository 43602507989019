import React from "react";
import styled from "styled-components";
import { CancelIcon, OkIcon } from "../../../components/Icons/TooltipYesNoIcons";

interface Props {
  type: "image" | "video";
}

const TooltipBig = ({ type }: Props) => {
  const dataForType = {
    image: {
      title: "Image Upload Guidelines:",
      strings: [
        "Face the camera directly.",
        "Ensure your face is clearly visible.",
        "Make sure you're the only one in the photo.",
        "Animals or objects may not give the expected results, but feel free to experiment and enjoy!",
      ],
    },
    video: {
      title: "Video Upload Guidelines:",
      strings: [
        "Face the camera directly.",
        "Ensure your face is clearly visible.",
        "Make sure you're the only one in the video.",
        "Upload a Video (512x512 (recommended) or 1024x1024).",
        "Minimize shoulder movement, neutral expression in first frame.",
      ],
    },
  };

  const tooltip = dataForType[type];

  const images = [
    { url: "images/tooltip/pic-1.png", icon: <OkIcon /> },
    { url: "images/tooltip/pic-2.png", icon: <CancelIcon /> },
    { url: "images/tooltip/pic-3.png", icon: <OkIcon /> },
    { url: "images/tooltip/pic-4.png", icon: <CancelIcon /> },
  ];

  return (
    <Wrapper>
      <Title>{tooltip.title}</Title>
      <Tips>
        {tooltip.strings.map((text, index) => (
          <li key={index}>{text}</li>
        ))}
      </Tips>
      <Images>
        {images.map((image, index) => (
          <Column key={index}>
            <ImageWrapper>
              <img src={image.url} alt="" />
            </ImageWrapper>
            {image.icon}
          </Column>
        ))}
      </Images>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s;
  min-width: 400px;
  visibility: hidden;
  background: #1e1e20;
  color: ${({ theme }) => theme.white};
  border-radius: 10px;
  z-index: 100;
`;

const Title = styled.p`
  width: 100%;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 20px !important;
`;

const Tips = styled.ul`
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  margin: 10px 20px;
  padding: 0 0 0 20px;

  li {
    list-style: disc outside !important;
  }
`;

const Images = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  gap: 10px;
  font-size: 12px;
  font-weight: 800;
  line-height: 1.5;
  margin: 10px 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 57px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export default TooltipBig;
