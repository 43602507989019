import {
  Canva,
  ChatGPT,
  CopyIconV3,
  Gallery,
  Logo,
  MicrophoneIconV2,
  Monitor,
  Profile2UserBlue,
  Translate,
  UserCircleAdd,
  UserSquare,
} from "../../components/Icons/Icons";

import { ContentCardData } from "../../modules/Home/components/navbarCard/ContentCard";

export const FeatureToolsData: ContentCardData[] = [
  {
    icon: <MicrophoneIconV2 />,
    title: "AI Voices",
    description: "Create exciting voiceovers with best in class voice actors",
    link: { to: "/actors" },
  },
  {
    icon: <Profile2UserBlue />,
    title: "AI Humans",
    description: "Text to Videos with real life AI Actors",
    link: { to: "/ai-humans" },
  },
  {
    icon: <Gallery />,
    title: "Generate AI Media",
    description: "Make your imagination take life in stunning images & videos",
    link: { to: "/ai-images" },
  },
  {
    icon: <Logo />,
    title: "Faceform",
    description: "Transform any character or video into a unique creation",
    link: { to: "/transform" },
    isNew: true,
  },
];

export const AIVoicesData: ContentCardData[] = [
  {
    icon: <MicrophoneIconV2 />,
    title: "AI Voices",
    description: "Create exciting voiceovers with best in class voice actors",
    link: { to: "/actors" },
  },
  {
    icon: <CopyIconV3 />,
    title: "Multilingual Voice Cloning",
    description: "Clone your voice to use in your voiceovers and videos",
  },
  {
    icon: <Monitor />,
    title: "Text to Speech API",
    description: "Connect the power of Synthesys with your own apps using TTS API",
    link: { to: "/api-access" },
  },
  {
    icon: <ChatGPT />,
    title: "AI Voices GPT",
    description: "Generate & Clone HQ voiceovers instantly in 17 languages",
    link: { to: "https://chatgpt.com/g/g-czgfNwUkc-synthesys-ai-voice-generator-ai-voice-cloning", target: "_blank" },
  },
  {
    icon: <Logo />,
    title: "Speech to speech",
    description: "Change the voice of an existing audio file with best in class voice actors",
    isDisabled: false,
    link: { to: "/actors?tab=Speech2Speech" },
  },
];

export const AIHumansData: ContentCardData[] = [
  {
    icon: <Profile2UserBlue />,
    title: "AI Humans",
    description: "Text to Videos with real life AI Actors",
    link: { to: "/ai-humans" },
  },
  {
    icon: <Gallery />,
    title: "Talking Images",
    description: "Bring your images to life",
  },

  {
    icon: <UserCircleAdd />,
    title: "Instant avatars",
    description: "Create your avatars instantly and use them to create exciting videos",
  },
  {
    icon: <UserSquare />,
    title: "Custom Studio Avatars",
    description: "Create your avatar and use it to create exciting videos",
    link: {
      to: "https://drive.google.com/file/d/1EPKie5KHEWZ7vXd6BwI-Ts-YsAOzTwDn/view?usp=sharing",
      target: "_blank",
    },
  },
];

export const SynthesysLabData: ContentCardData[] = [
  {
    icon: <Gallery />,
    title: "Generate AI Media",
    description: "Make your imagination take life in stunning images & videos",
    link: { to: "/ai-images" },
  },
  {
    icon: <CopyIconV3 />,
    title: "Synthesys X",
    description: "Replicate any images using our powerful Google Chrome extension",
    link: {
      to: "https://chromewebstore.google.com/detail/synthesys-x/jckigdhmponckmebbhbfenjmofkklnif",
      target: "_blank",
    },
  },
  {
    icon: <Canva />,
    title: "Synthesys AI Humans Canva",
    description: "Create videos from prompts and enhance your Canva project",
    link: {
      to: "https://www.canva.com/your-apps/AAF9O6t4hcc/synthesys-humans?q=ai+humans",
      target: "_blank",
    },
  },
  {
    icon: <Canva />,
    title: "Synthesys AI Video Canva",
    description: "Create realistic and imaginative scenes from text in Canva",
    link: {
      to: "https://www.canva.com/your-apps/AAGBilBjQ4w/synthesys-ai-video?q=ai+video",
      target: "_blank",
    },
  },
  {
    icon: <Translate />,
    title: "Synthesys Video Translate",
    description: "Translate existing videos in 29 different languages",
    link: { to: "/video-translation" },
  },
  {
    icon: <Logo />,
    title: "Faceform",
    description: "Transform any character or video into a unique creation",
    link: { to: "/transform" },
    isNew: true,
  },
];
