import { CheckIconThick } from "../../components/Icons/CheckIconThick";

export const languages = [
  {
    label: "English",
    value: "en",
    icon: "https://flagcdn.com/w40/gb.png",
    endAddornment: <CheckIconThick />,
  },
  {
    label: "German",
    value: "de",
    icon: "https://flagcdn.com/w40/de.png",
    endAddornment: <CheckIconThick />,
  },
  {
    label: "Spanish",
    value: "es",
    icon: "https://flagcdn.com/w40/es.png",
    endAddornment: <CheckIconThick />,
  },
  {
    label: "French",
    value: "fr",
    icon: "https://flagcdn.com/w40/fr.png",
    endAddornment: <CheckIconThick />,
  },
  {
    label: "Japanese",
    value: "ja",
    icon: "https://flagcdn.com/w40/jp.png",
    endAddornment: <CheckIconThick />,
  },
  {
    label: "Portuguese",
    value: "pt",
    icon: "https://flagcdn.com/w40/pt.png",
    endAddornment: <CheckIconThick />,
  },
  {
    label: "Chinese",
    value: "zh",
    icon: "https://flagcdn.com/w40/cn.png",
    endAddornment: <CheckIconThick />,
  },
  {
    label: "Hindi",
    value: "hi",
    icon: "https://flagcdn.com/w40/in.png",
    endAddornment: <CheckIconThick />,
  },
  {
    label: "Italian",
    value: "it",
    icon: "https://flagcdn.com/w40/it.png",
    endAddornment: <CheckIconThick />,
  },
  {
    label: "Korean",
    value: "ko",
    icon: "https://flagcdn.com/w40/kr.png",
    endAddornment: <CheckIconThick />,
  },
  {
    label: "Dutch",
    value: "nl",
    icon: "https://flagcdn.com/w40/nl.png",
    endAddornment: <CheckIconThick />,
  },
  {
    label: "Polish",
    value: "pl",
    icon: "https://flagcdn.com/w40/pl.png",
    endAddornment: <CheckIconThick />,
  },
  {
    label: "Russian",
    value: "ru",
    icon: "https://flagcdn.com/w40/ru.png",
    endAddornment: <CheckIconThick />,
  },
  {
    label: "Swedish",
    value: "se",
    icon: "https://flagcdn.com/w40/se.png",
    endAddornment: <CheckIconThick />,
  },
  {
    label: "Turkish",
    value: "tr",
    icon: "https://flagcdn.com/w40/tr.png",
    endAddornment: <CheckIconThick />,
  },
];
