import { ProfileModules, User } from "../../types/profile";
import {
  CLEAR_USER_HISTORY_PROJECTS,
  DELETE_HISTORY_PROJECT_SERVER,
  FAVORITE_HISTORY_PROJECT_SERVER,
  GENERATE_API_KEY_SERVER,
  GET_MY_PROFILE_SERVER,
  GET_USER_HISTORY_PROJECTS_INFINITE_SERVER,
  GET_USER_HISTORY_PROJECTS_SERVER,
  REVOKE_API_KEY_SERVER,
  SET_USER_HISTORY_PROJECTS_PAGE,
  UPDATE_HAS_MORE_USER_HISTORY_PROJECTS,
  UPDATE_HISTORY_PROJECT_SERVER,
  UPDATE_PROFILE,
  UPDATE_PROFILE_LOADING,
  UPLOAD_PROFILE_PIC,
} from "../actions/profileActions";
import { StoreType } from "../../types/store";
import { SIGN_IN_SERVER, SIGN_IN_WITH_GOOGLE_SERVER } from "../actions/authActions";
import { UserHistoryProject } from "../../types/userHistoryProjects";
import { toast } from "react-toastify";

export interface profileStateType {
  [ProfileModules.myProfile]: {
    profile: User;
    isLoading: boolean;
  };
  [ProfileModules.updateProfile]: {
    isLoading: boolean;
  };
  [ProfileModules.changePassword]: {
    isLoading: boolean;
  };
  [ProfileModules.checkCloneVoice]: {
    isLoading: boolean;
  };
  [ProfileModules.checkCustomAvatar]: {
    isLoading: boolean;
  };
  [ProfileModules.cloneVoice]: {
    isLoading: boolean;
  };
  [ProfileModules.generateApiKey]: {
    isLoading: boolean;
  };
  [ProfileModules.loadingPage]: {
    isLoading: boolean;
  };
  [ProfileModules.deleteAccount]: {
    isLoading: boolean;
  };
  [ProfileModules.userHistoryProjects]: {
    data: UserHistoryProject[];
    isLoading: boolean;
    isDeleteLoading: boolean;
    hasMore: boolean;
    pageNumber: number;
    totalPages: number;
    totalRecords: number;
  };
}

const profileInitialState: profileStateType = {
  [ProfileModules.myProfile]: {
    profile: {} as User,
    isLoading: false,
  },
  [ProfileModules.updateProfile]: {
    isLoading: false,
  },
  [ProfileModules.changePassword]: {
    isLoading: false,
  },
  [ProfileModules.checkCloneVoice]: {
    isLoading: false,
  },
  [ProfileModules.checkCustomAvatar]: {
    isLoading: false,
  },
  [ProfileModules.cloneVoice]: {
    isLoading: false,
  },
  [ProfileModules.generateApiKey]: {
    isLoading: false,
  },
  [ProfileModules.loadingPage]: {
    isLoading: true,
  },
  [ProfileModules.deleteAccount]: {
    isLoading: false,
  },
  [ProfileModules.userHistoryProjects]: {
    data: [],
    isLoading: false,
    isDeleteLoading: false,
    hasMore: true,
    pageNumber: 0,
    totalPages: 0,
    totalRecords: 0,
  },
};

const profileReducer = (state = profileInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_PROFILE_LOADING: {
      const { module, isLoading } = action.payload;
      return { ...state, [module]: { ...state[module as ProfileModules], isLoading } };
    }
    case `${GET_MY_PROFILE_SERVER}_SUCCESS`: {
      return {
        ...state,
        [ProfileModules.myProfile]: {
          ...state[ProfileModules.myProfile],
          profile: action.payload.data.data,
        },
      };
    }
    case `${GENERATE_API_KEY_SERVER}_SUCCESS`: {
      return {
        ...state,
        [ProfileModules.myProfile]: {
          ...state[ProfileModules.myProfile],
          profile: {
            ...state[ProfileModules.myProfile].profile,
            apiKey: action.payload.data.data,
          },
        },
      };
    }
    case `${REVOKE_API_KEY_SERVER}_SUCCESS`: {
      return {
        ...state,
        [ProfileModules.myProfile]: {
          ...state[ProfileModules.myProfile],
          profile: {
            ...state[ProfileModules.myProfile].profile,
            apiKey: "",
          },
        },
      };
    }
    case UPDATE_PROFILE: {
      return {
        ...state,
        [ProfileModules.myProfile]: {
          ...state[ProfileModules.myProfile],
          profile: action.payload,
        },
      };
    }
    case UPLOAD_PROFILE_PIC: {
      return {
        ...state,
        [ProfileModules.myProfile]: {
          ...state[ProfileModules.myProfile],
          profile: {
            ...state[ProfileModules.myProfile].profile,
            profilePic: action.payload,
          },
        },
      };
    }
    case `${SIGN_IN_WITH_GOOGLE_SERVER}_SUCCESS`:
    case `${SIGN_IN_SERVER}_SUCCESS`: {
      if (action.payload.data.data) {
        const { user } = action.payload.data.data;

        return {
          ...state,
          [ProfileModules.myProfile]: {
            ...state[ProfileModules.myProfile],
            profile: user,
          },
        };
      } else {
        return { ...state };
      }
    }

    case `${GET_USER_HISTORY_PROJECTS_SERVER}_SUCCESS`: {
      const { pageNumber, data, totalPages, totalRecords } = action.payload.data;

      return {
        ...state,
        [ProfileModules.userHistoryProjects]: {
          ...state[ProfileModules.userHistoryProjects],
          data,
          pageNumber,
          totalPages,
          totalRecords,
        },
      };
    }

    case GET_USER_HISTORY_PROJECTS_INFINITE_SERVER: {
      return {
        ...state,
        [ProfileModules.userHistoryProjects]: {
          ...state[ProfileModules.userHistoryProjects],
          hasMore: true,
        },
      };
    }

    case `${GET_USER_HISTORY_PROJECTS_INFINITE_SERVER}_SUCCESS`: {
      const { pageNumber, data } = action.payload.data;

      return {
        ...state,
        [ProfileModules.userHistoryProjects]: {
          ...state[ProfileModules.userHistoryProjects],
          data: pageNumber === 1 ? data : [...state[ProfileModules.userHistoryProjects].data, ...data],
          pageNumber: state[ProfileModules.userHistoryProjects].pageNumber + 1,
        },
      };
    }

    case UPDATE_HAS_MORE_USER_HISTORY_PROJECTS: {
      return {
        ...state,
        [ProfileModules.userHistoryProjects]: {
          ...state[ProfileModules.userHistoryProjects],
          hasMore: action.payload.hasMore,
        },
      };
    }

    case SET_USER_HISTORY_PROJECTS_PAGE: {
      return {
        ...state,
        [ProfileModules.userHistoryProjects]: {
          ...state[ProfileModules.userHistoryProjects],
          pageNumber: action.payload.pageNumber,
        },
      };
    }

    case CLEAR_USER_HISTORY_PROJECTS: {
      return {
        ...state,
        [ProfileModules.userHistoryProjects]: {
          ...state[ProfileModules.userHistoryProjects],
          data: [],
          pageNumber: 0,
        },
      };
    }

    case UPDATE_HISTORY_PROJECT_SERVER: {
      const { id, category, title } = action.payload.request.data;

      const newProjects = state[ProfileModules.userHistoryProjects].data.map((project) =>
        project.id === id && project.category === category ? { ...project, title } : project,
      );

      return {
        ...state,
        [ProfileModules.userHistoryProjects]: {
          ...state[ProfileModules.userHistoryProjects],
          data: newProjects,
        },
      };
    }

    case `${UPDATE_HISTORY_PROJECT_SERVER}_FAIL`: {
      const { id, category } = action.meta.previousAction.payload.request.data;
      const { oldTitle } = action.meta.previousAction.payload;

      const oldProjects = state[ProfileModules.userHistoryProjects].data.map((project) =>
        project.id === id && project.category === category ? { ...project, title: oldTitle } : project,
      );

      toast.error("Error while renaming project");

      return {
        ...state,
        [ProfileModules.userHistoryProjects]: {
          ...state[ProfileModules.userHistoryProjects],
          data: oldProjects,
        },
      };
    }

    case DELETE_HISTORY_PROJECT_SERVER: {
      return {
        ...state,
        [ProfileModules.userHistoryProjects]: {
          ...state[ProfileModules.userHistoryProjects],
          isDeleteLoading: true,
        },
      };
    }

    case `${DELETE_HISTORY_PROJECT_SERVER}_FAIL`:
    case `${DELETE_HISTORY_PROJECT_SERVER}_SUCCESS`: {
      return {
        ...state,
        [ProfileModules.userHistoryProjects]: {
          ...state[ProfileModules.userHistoryProjects],
          isDeleteLoading: false,
        },
      };
    }

    case FAVORITE_HISTORY_PROJECT_SERVER: {
      const { id, category, favorite } = action.payload.request.data;

      const newProjects = state[ProfileModules.userHistoryProjects].data.map((project) =>
        project.id === id && project.category === category ? { ...project, favorite } : project,
      );

      return {
        ...state,
        [ProfileModules.userHistoryProjects]: {
          ...state[ProfileModules.userHistoryProjects],
          data: newProjects,
        },
      };
    }

    case `${FAVORITE_HISTORY_PROJECT_SERVER}_FAIL`: {
      const { id, category } = action.meta.previousAction.payload.request.data;
      const { oldFavorite } = action.meta.previousAction.payload;

      const newProjects = state[ProfileModules.userHistoryProjects].data.map((project) =>
        project.id === id && project.category === category ? { ...project, favorite: oldFavorite } : project,
      );

      return {
        ...state,
        [ProfileModules.userHistoryProjects]: {
          ...state[ProfileModules.userHistoryProjects],
          data: newProjects,
        },
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const getProfile = (state: StoreType) => state.profile[ProfileModules.myProfile].profile;
export const getProfileLoading = (state: StoreType) => state.profile[ProfileModules.myProfile].isLoading;

export const getChangePasswordLoading = (state: StoreType) => state.profile[ProfileModules.changePassword].isLoading;

export const getCheckCloneVoiceLoading = (state: StoreType) => state.profile[ProfileModules.checkCloneVoice].isLoading;
export const getCloneVoiceLoading = (state: StoreType) => state.profile[ProfileModules.cloneVoice].isLoading;

export const getCheckCustomAvatarLoading = (state: StoreType) =>
  state.profile[ProfileModules.checkCustomAvatar].isLoading;

export const getGenerateApiKeyLoading = (state: StoreType) => state.profile[ProfileModules.generateApiKey].isLoading;
export const deleteAccountLoading = (state: StoreType) => state.profile[ProfileModules.deleteAccount].isLoading;

export const getUpdateProfileLoading = (state: StoreType) => state.profile[ProfileModules.updateProfile].isLoading;

export const getUserHistoryProjects = (state: StoreType) => state.profile[ProfileModules.userHistoryProjects].data;
export const getUserHistoryProjectsLoading = (state: StoreType) =>
  state.profile[ProfileModules.userHistoryProjects].isLoading;
export const getHasMoreUserHistoryProjects = (state: StoreType) =>
  state.profile[ProfileModules.userHistoryProjects].hasMore;
export const getCurrentPageUserHistoryProjects = (state: StoreType) =>
  state.profile[ProfileModules.userHistoryProjects].pageNumber;
export const getUserHistoryProjectsTotalPages = (state: StoreType) =>
  state.profile[ProfileModules.userHistoryProjects].totalPages;
export const getUserHistoryProjectsTotalRecords = (state: StoreType) =>
  state.profile[ProfileModules.userHistoryProjects].totalRecords;
export const getUserHistoryProjectsIsDeleteLoading = (state: StoreType) =>
  state.profile[ProfileModules.userHistoryProjects].isDeleteLoading;

export const getLoadingPage = (state: StoreType) => state.profile[ProfileModules.loadingPage].isLoading;

export default profileReducer;
