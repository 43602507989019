import styled from "styled-components";
import { useDispatch } from "react-redux";
import { formatDistance } from "date-fns";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import MenuItem from "../ActionMenu/MenuItem";
import ActionMenu from "../ActionMenu/ActionMenu";
import Tooltip from "../../modules/MyStudio/components/Tooltip";
import ProgressBarCircle from "../../modules/MyStudio/components/ProgressBarCircle";
import IconButton, { IconButtonThemes } from "../Button/IconButton";
import Textfield, { TextfieldVariant } from "../Textfield/Textfield";
import {
  ClockIcon,
  DownloadIcon,
  DuplicateIcon,
  EditIcon,
  OptionsIcon,
  PopupUploadIcon,
  TrashIcon,
} from "../Icons/Icons";
import { pages } from "../../lib/routeUtils";
import { Project } from "../../types/project";
import { downloadFile } from "../../lib/downloadFile";
import { AIHumansStatus } from "../../modules/MyStudio";
import { downloadImage } from "../../lib/downloadImage";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { renameProjectServer } from "../../redux/actions/projectAction";
import { ConfirmationDelete, DeleteType } from "../../types/confirmationDelete";

interface ProjectCardProps {
  project: Project;
  title: string;
  projectTime?: string;
  image?: string;
  status?: AIHumansStatus;
  selectedStatus?: AIHumansStatus | null;
  output?: string;
  active?: boolean;
  setActiveProject: any;
  handleActiveProject: (e: any) => void;
}

const ProjectCardLarge = ({
  project,
  title,
  projectTime,
  image,
  status,
  selectedStatus,
  output,
  active,
  setActiveProject,
  handleActiveProject,
}: ProjectCardProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [projectName, setProjectName] = useState<string>(title);
  const [menuOpen, setMenuOpen] = useState<number>();
  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  const handleChange = (e: any) => setProjectName(e.target.value);

  const handleKeyPress = (e: any, type?: "blur") => {
    if (e.code === "Enter" || type) {
      if (!project) throw Error("Project entity is missing while renaming");
      if (project && projectName !== project?.title) {
        dispatch(
          renameProjectServer({
            projectId: project?.projectId,
            projectTypeId: project?.projectTypeId,
            newTitle: projectName,
            oldTitle: project?.title,
          }),
        );
      }
      setActiveProject(null);
    }
  };

  useEffect(() => {
    setProjectName(project.title);
  }, [project.title]);

  const renameProject = (e: any) => {
    handleActiveProject(e);
    handleCloseMenu(e);
  };

  const handleDuplicate = (e: any) => {
    navigate(pages.aiHumans() + `?template=${project?.projectId}`);

    handleCloseMenu(e);
  };

  const handleDeleteClick = (e: any) => {
    if (!project.projectId) {
      throw Error("Project entity is missing while deleting");
    }
    e.stopPropagation();
    e.preventDefault();

    const prefilled: ConfirmationDelete = {
      id: project.projectId,
      title: "Are you sure you want to delete project " + project.title,
      description: "The project will be removed and the action cannot be undone",
      typeId: 2,
      type: DeleteType.Project,
      status: selectedStatus,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const handleOpenMenu = (e: any, id: number) => {
    e.preventDefault();
    e.stopPropagation();

    setMenuOpen(id);
  };

  const handleCloseMenu = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setMenuOpen(-1);
  };

  const handlePopupOpen = (e: any, status?: AIHumansStatus) => {
    if (status === AIHumansStatus.Completed) {
      e.preventDefault();
      e.stopPropagation();
      project && searchParams.set("projectId", project!.projectId!.toString());
      navigate(`?${searchParams.toString()}`);

      const prefilled = {
        type: DeleteType.Project,
        typeId: 2,
        status: selectedStatus,
      };

      dispatch(
        updatePopup({
          popup: Popups.historyPopup,
          status: true,
          prefilled,
        }),
      );
    }
  };

  const menu = [
    {
      icon: <OptionsIcon />,
      options: [
        {
          name: "Duplicate",
          icon: <DuplicateIcon />,
          hidden: status === AIHumansStatus.InProgress,
        },
        {
          name: "Download",
          icon: <DownloadIcon />,
          hidden: status !== AIHumansStatus.Completed,
        },
        {
          name: "Rename",
          icon: <EditIcon />,
        },
        {
          name: "Delete",
          icon: <TrashIcon />,
        },
      ],
    },
  ];

  const lastUpdateTime = new Date(projectTime + "Z");

  const timeElapsed = (Number(currentTime) - Number(lastUpdateTime)) / 1000;
  const percentComplete = (timeElapsed / Number(project.estimatedTime)) * 100;

  useEffect(() => {
    if (status === AIHumansStatus.InProgress) {
      const intervalId = setInterval(() => setCurrentTime(new Date()), 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [status]);

  return (
    <Wrapper className="project-card-large" onClick={(e: any) => handlePopupOpen(e, status)}>
      <ImgWrapper>
        {image ? <Image src={image} alt={title} /> : <EmptyImage />}
        {status && (
          <>
            {status !== AIHumansStatus.Draft && (
              <ProgressBarCircleWrapper>
                <ProgressBarCircle percent={percentComplete} status={status} />
              </ProgressBarCircleWrapper>
            )}
            {status !== AIHumansStatus.InProgress && (
              <BadgeWrapper>
                <Badge status={status}>
                  {status === AIHumansStatus.Completed
                    ? project.totalDuration + "s"
                    : AIHumansStatus[status!.valueOf()]}
                </Badge>
              </BadgeWrapper>
            )}
          </>
        )}
        {status === AIHumansStatus.Completed && (
          <>
            <IconButton
              iconButtonTheme={IconButtonThemes.Transparent}
              icon={<PopupUploadIcon />}
              onClick={(e: any) => {
                e.preventDefault();
                project.output && downloadImage(e, project.output);
              }}
            />
          </>
        )}
      </ImgWrapper>
      <Fade>
        <TitleWrapper>
          {!active ? (
            <>
              {status === AIHumansStatus.Failure && (
                <StatusIconWrapper>
                  <Tooltip />
                </StatusIconWrapper>
              )}
              <Title title={title}>{title}</Title>
              <ActionWrapper>
                {menu.map((m, order) => (
                  <ActionMenu
                    key={order}
                    position={{
                      x: "left",
                      y: "top",
                    }}
                    open={menuOpen === order}
                    menuStyle={{
                      bottom: "0px",
                      right: "28px",
                      padding: "8px 12px",
                      background: "#0286DC",
                      border: "none",
                    }}
                    handleClose={handleCloseMenu}
                    trigger={
                      <IconButton
                        iconButtonTheme={IconButtonThemes.Transparent}
                        icon={m.icon}
                        onClick={order === 0 ? (e: any) => handleOpenMenu(e, order) : () => null}
                      />
                    }
                  >
                    {m.options && (
                      <MenuWrapper>
                        {m.options.map((option, index) =>
                          option.hidden ? (
                            <></>
                          ) : (
                            <MenuItem
                              key={index}
                              icon={option.icon}
                              onClick={(e: any) => {
                                e.preventDefault();
                                option.name === "Duplicate" && handleDuplicate(e);
                                option.name === "Download" && downloadFile(e, output || "", status);
                                option.name === "Open this video" && navigate(`/video/${project.projectId}`);
                                option.name === "Rename" && renameProject(e);
                                option.name === "Delete" && handleDeleteClick(e);
                              }}
                            >
                              {option.name}
                            </MenuItem>
                          ),
                        )}
                      </MenuWrapper>
                    )}
                  </ActionMenu>
                ))}
              </ActionWrapper>
            </>
          ) : (
            <Textfield
              autoFocus
              variant={TextfieldVariant.project}
              value={projectName}
              placeholder="Enter project name"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              onBlur={(e) => handleKeyPress(e, "blur")}
            />
          )}
        </TitleWrapper>
        {projectTime && (
          <ProjectTime>
            <ProjectTimeText>{formatDistance(new Date(projectTime + "Z"), new Date())} ago</ProjectTimeText>
            <ClockIcon />
          </ProjectTime>
        )}
      </Fade>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 269px;
  height: 260px;
  position: relative;
`;

const ImgWrapper = styled.div`
  height: 194px;
  position: relative;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 4px 4px 0px #00000040;

  & > button {
    position: absolute;
    top: 6px;
    right: 8px;
    width: 20px;
    height: 20px;
    // background: ${({ theme }) => theme.white};
    border-radius: 6px;
    // border: 1px solid #0286dc;

    svg path {
      fill: #0286dc;
    }
  }
`;

const ProgressBarCircleWrapper = styled.div`
  position: absolute;
  top: 6px;
  left: 10px;
`;

const BadgeWrapper = styled.div`
  position: absolute;
  bottom: 6px;
  left: 10px;
`;

const Badge = styled.div<{ status?: AIHumansStatus }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 22px;

  ${({ status }) =>
    status === AIHumansStatus.Draft &&
    `
    font-size: 14px;
    font-weight: 400;
    background: #FFDB20;
    color: #F22121;
    `}

  ${({ status }) =>
    status === AIHumansStatus.Completed &&
    `
    font-size: 12px;
    font-weight: 400;
    background: #0063B4;
    color: #FFFFFF;
    `}

  ${({ status }) =>
    status === AIHumansStatus.Failure &&
    `
    font-size: 12px;
    font-weight: 400;
    background: #DC2020;
    color: #FFFFFF;
    `}
`;

const Fade = styled.div`
  height: auto;

  display: flex;
  flex-direction: column;
  padding: 8px 0px 14px;
  gap: 1px;

  @media (max-width: 550px) {
    align-items: center;
    row-gap: 2px;
    height: 60px;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 4px;
  padding: 0 10px;

  button {
    svg > path {
      fill: ${({ theme }) => theme.icon};
    }
  }

  .textfield-component-wrapper {
    width: 100%;

    input {
      max-width: inherit;
    }
  }
`;

const Title = styled.p`
  flex: 1;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  max-width: 190px;
  color: ${({ theme }) => theme.primaryText};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-word;

  @media (max-width: 550px) {
    line-height: 14px;
  }
`;

const ProjectTime = styled.div`
  display: flex;
  flex-direction: row-reverse;
  column-gap: 2px;
  align-items: center;
  justify-content: start;
  opacity: 0.6;
  padding-left: 10px;

  svg {
    path {
      fill: ${({ theme }) => theme.primaryText};
    }
  }
`;

const ProjectTimeText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.primaryText};
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;

const EmptyImage = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.disableIcon}33;
  border-radius: 3px;
`;

const StatusIconWrapper = styled.div`
  display: flex;
  margin-right: 4px;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 3px;
  right: 6px;

  span {
    button {
      width: 24px;
      height: 24px;
      border: none;
      box-shadow: none;
      border-radius: 6px;

      svg {
      width: 20px;
      height: 20px;
        path {
         fill: ${({ theme }) => theme.primaryText}BA;
       }
      }

      &:focus {
        border: none;
      }
    }
`;

const MenuWrapper = styled.div`
  width: 140px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;

  & div {
    background: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    background-clip: unset;
    color: #ffffff;

    & path {
      fill: #ffffff;
    }
  }

  .alternate-color {
    rect {
      fill: #ffffff;
    }
    path {
      fill: #0286dc;
    }
  }
`;

export default ProjectCardLarge;
