export const OkIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Add, remove, cancel/ok">
      <path id="Path 3" d="M4 13L9 18L20 7" stroke="#00DC00" strokeLinecap="round" />
    </g>
  </svg>
);

export const CancelIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="close">
      <path id="Path 2" d="M17.6569 17.6568L6.34319 6.34309" stroke="#FF0000" strokeLinecap="round" />
      <path id="Path 2_2" d="M17.6568 6.34309L6.34311 17.6568" stroke="#FF0000" strokeLinecap="round" />
    </g>
  </svg>
);
