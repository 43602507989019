import React from "react";
import styled from "styled-components";

import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarLayout from "../../layouts/SidebarLayout";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import SecreteKeyGeneration from "./components/SecreteKeyGeneration";
import ApiAccessInformationBox from "./components/ApiAccessInformation";
import DownloadTime from "../../components/DownloadTime/DownloadTime";
import { useSelector } from "react-redux";
import { getProfile } from "../../redux/reducers/profileReducer";
import { formatMinutes } from "../../components/Navigation/Navigation";
import { Link } from "react-router-dom";
import { ArrowRight } from "../../components/Icons/ArrowRight";
import { pages } from "../../lib/routeUtils";

const ApiAccessPage = () => {
  const profile = useSelector(getProfile);

  const apiAllowed = profile.aiVoicesApiAllowed || 0;
  const apiLeft = profile.aiVoicesApiAllowed - (profile.aiVoicesApiUsed || 0);
  const apiUsed = apiAllowed - apiLeft;
  const progress = (100 * (apiAllowed - apiUsed)) / apiAllowed;

  return (
    <Wrapper>
      <SidebarLayout>
        <Content>
          <MobileOnly>
            <Title>Voice only</Title>
          </MobileOnly>
          <div style={{ width: "100%" }}>
            <MobileOnly>
              <Sidebar mobile />
            </MobileOnly>
            <DesktopOnly>
              <Title>Voice only</Title>
            </DesktopOnly>
          </div>
          <ApiAccessContentWrapper>
            <Column>
              {(apiLeft !== 0 || apiAllowed !== 0) && (
                <DownloadTime
                  title="API usage time left"
                  timeLeft={`${apiLeft} out of ${apiAllowed}`}
                  progress={progress}
                />
              )}
              <SecreteKeyGeneration />
              <LinkWrapper to={pages.pricing()}>
                <span>View Pricing</span>
                <ArrowRight />
              </LinkWrapper>
            </Column>
            <div style={{ maxWidth: "462px" }}>
              <ApiAccessInformationBox />
            </div>
          </ApiAccessContentWrapper>
        </Content>
      </SidebarLayout>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;

  .container {
    max-width: 1200px;
  }

  @media (max-width: 1001px) {
    & .navigation {
      visibility: unset;
      height: 88px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  overflow: hidden auto;
  flex: 1;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 1001px) {
    display: flex;

    & > div {
      display: flex;
    }
  }
`;

const DesktopOnly = styled.div`
  flex: 1;

  @media (max-width: 1001px) {
    display: none;
  }
`;

const Title = styled("h1")`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.primaryText};
  flex: 1;
`;

const ApiAccessContentWrapper = styled.div`
  display: flex;
  align-items: start;
  gap: 16px;

  & > div {
    flex: 1;
  }

  @media (max-width: 1001px) {
    flex-direction: column;

    & > div {
      max-width: 100% !important;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > div:first-of-type {
    max-width: 100%;

    @media (max-width: 900px) {
      display: flex;
    }
  }
`;

const LinkWrapper = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
  background: ${({ theme }) => theme.activeMenu};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  svg {
    path {
      fill: ${({ theme }) => theme.activeMenu};
    }
  }
`;

export default withPrivateRoute(ApiAccessPage);
