import { toast } from "react-toastify";
import { put, takeEvery } from "redux-saga/effects";
import {
  CREATE_AI_SERVICE_SERVER,
  CREATE_FACESWAP_SERVER,
  DELETE_AI_SERVICE_PROJECT_SERVER,
  DELETE_FACESWAP_PROJECT_SERVER,
  GENERATE_AUDIO_SERVER,
  GET_AI_SERVICE_BY_ID_SERVER,
  GET_AI_SERVICE_LIST_SERVER,
  GET_FACESWAP_IMAGE_FROM_TEXT_SERVER,
  GET_FACESWAP_LIST_SERVER,
  GET_FACESWAP_SERVER,
  UPLOAD_FILE_SERVER,
  clearFaceswap,
  clearFaceswapList,
  getAIServiceListServer,
  getFaceswapListServer,
  updateFaceswapLoading,
} from "../actions/faceswapActions";
import { FaceswapModules } from "../../types/faceSwapType";
import { Popups, updatePopup } from "../actions/popupsActions";

const faceswapSaga = [
  takeEvery(`${UPLOAD_FILE_SERVER}`, handleUploadFileServer),
  takeEvery(`${UPLOAD_FILE_SERVER}_FAIL`, handleUploadFileServerFail),
  takeEvery(`${UPLOAD_FILE_SERVER}_SUCCESS`, handleUploadFileServerSuccess),

  takeEvery(`${GENERATE_AUDIO_SERVER}`, handleGenerateAudioServer),
  takeEvery(`${GENERATE_AUDIO_SERVER}_FAIL`, handleGenerateAudioServerFail),
  takeEvery(`${GENERATE_AUDIO_SERVER}_SUCCESS`, handleGenerateAudioServerSuccess),

  takeEvery(`${CREATE_FACESWAP_SERVER}`, handleCreateFaceswapServer),
  takeEvery(`${CREATE_FACESWAP_SERVER}_FAIL`, handleCreateFaceswapServerFail),
  takeEvery(`${CREATE_FACESWAP_SERVER}_SUCCESS`, handleCreateFaceswapServerSuccess),

  takeEvery(`${CREATE_AI_SERVICE_SERVER}`, handleCreateFaceswapServer),
  takeEvery(`${CREATE_AI_SERVICE_SERVER}_FAIL`, handleCreateFaceswapServerFail),
  takeEvery(`${CREATE_AI_SERVICE_SERVER}_SUCCESS`, handleCreateAIServiceServerSuccess),

  takeEvery(`${GET_FACESWAP_LIST_SERVER}`, handleGetFaceswapListServer),
  takeEvery(`${GET_FACESWAP_LIST_SERVER}_FAIL`, handleGetFaceswapListServerFail),
  takeEvery(`${GET_FACESWAP_LIST_SERVER}_SUCCESS`, handleGetFaceswapListServerSuccess),

  takeEvery(`${GET_AI_SERVICE_LIST_SERVER}`, handleGetFaceswapListServer),
  takeEvery(`${GET_AI_SERVICE_LIST_SERVER}_FAIL`, handleGetFaceswapListServerFail),
  takeEvery(`${GET_AI_SERVICE_LIST_SERVER}_SUCCESS`, handleGetFaceswapListServerSuccess),

  takeEvery(GET_FACESWAP_SERVER, handleGetFaceswapServer),
  takeEvery(`${GET_FACESWAP_SERVER}_SUCCESS`, handleGetFaceswapServerSuccess),
  takeEvery(`${GET_FACESWAP_SERVER}_FAIL`, handleGetFaceswapServerFail),

  takeEvery(GET_AI_SERVICE_BY_ID_SERVER, handleGetFaceswapServer),
  takeEvery(`${GET_AI_SERVICE_BY_ID_SERVER}_SUCCESS`, handleGetFaceswapServerSuccess),
  takeEvery(`${GET_AI_SERVICE_BY_ID_SERVER}_FAIL`, handleGetFaceswapServerFail),

  takeEvery(GET_FACESWAP_IMAGE_FROM_TEXT_SERVER, handleGetFaceswapImageFromTextServer),
  takeEvery(`${GET_FACESWAP_IMAGE_FROM_TEXT_SERVER}_SUCCESS`, handleGetFaceswapImageFromTextServerSuccess),
  takeEvery(`${GET_FACESWAP_IMAGE_FROM_TEXT_SERVER}_FAIL`, handleGetFaceswapImageFromTextServerFail),

  takeEvery(`${DELETE_FACESWAP_PROJECT_SERVER}_FAIL`, handleDeleteFaceswapProjectFail),
  takeEvery(`${DELETE_FACESWAP_PROJECT_SERVER}_SUCCESS`, handleDeleteFaceswapProjectSuccess),

  takeEvery(`${DELETE_AI_SERVICE_PROJECT_SERVER}_FAIL`, handleDeleteFaceswapProjectFail),
  takeEvery(`${DELETE_AI_SERVICE_PROJECT_SERVER}_SUCCESS`, handleDeleteFaceswapProjectSuccess),
];

function* handleUploadFileServer(action: any) {
  const { type }: { type: FaceswapModules } = action.payload;

  yield put(updateFaceswapLoading({ module: type, isLoading: true }));
}

function* handleUploadFileServerFail(action: any) {
  const { type }: { type: FaceswapModules } = action.meta.previousAction.payload;

  yield toast.error("There was an error when uploading an image");
  yield put(updateFaceswapLoading({ module: type, isLoading: false }));
}

function* handleUploadFileServerSuccess(action: any) {
  const { type }: { type: FaceswapModules } = action.meta.previousAction.payload;

  yield put(updateFaceswapLoading({ module: type, isLoading: false }));
}

function* handleGenerateAudioServer() {
  yield put(updateFaceswapLoading({ module: FaceswapModules.target, isLoading: true }));
}

function* handleGenerateAudioServerFail() {
  yield toast.error("An error occurred during audio generation");
  yield put(updateFaceswapLoading({ module: FaceswapModules.target, isLoading: false }));
}

function* handleGenerateAudioServerSuccess() {
  yield put(updateFaceswapLoading({ module: FaceswapModules.target, isLoading: false }));
}

function* handleCreateFaceswapServer() {
  yield put(updateFaceswapLoading({ module: FaceswapModules.faceswap, isLoading: true }));
}

function* handleCreateFaceswapServerFail(action: any) {
  yield toast.error(action.error.response.data.message || "An error occurred during video generation");
  yield put(updateFaceswapLoading({ module: FaceswapModules.faceswap, isLoading: false }));
}

function* handleCreateFaceswapServerSuccess() {
  yield put(getFaceswapListServer({ isLoading: true }));
}

function* handleCreateAIServiceServerSuccess(action: any) {
  const { serviceTypeID } = action.payload.data.data;
  yield put(getAIServiceListServer({ serviceTypeID, isLoading: true }));
}

function* handleGetFaceswapListServer(action: any) {
  const { isLoading } = action.payload;

  if (!isLoading) return;

  yield put(updateFaceswapLoading({ module: FaceswapModules.faceswapList, isLoading: true }));
}

function* handleGetFaceswapListServerFail() {
  yield toast.error("Error when retrieving the latest generated videos");
  yield put(updateFaceswapLoading({ module: FaceswapModules.faceswapList, isLoading: false }));
}

function* handleGetFaceswapListServerSuccess() {
  yield put(updateFaceswapLoading({ module: FaceswapModules.faceswapList, isLoading: false }));
}

function* handleGetFaceswapServer(action: any) {
  const { isProjectRestored } = action.payload;

  if (isProjectRestored) {
    yield put(clearFaceswap());
    yield put(updateFaceswapLoading({ module: FaceswapModules.pageLoading, isLoading: true }));
  }
}

function* handleGetFaceswapServerSuccess(action: any) {
  const { status } = action.payload.data.data;

  if (status === 3) {
    yield put(updateFaceswapLoading({ module: FaceswapModules.faceswap, isLoading: false }));
  } else if (status === 4) {
    toast.error("Failed to generate video. Contact support!");
    yield put(updateFaceswapLoading({ module: FaceswapModules.faceswap, isLoading: false }));
  }

  yield put(updateFaceswapLoading({ module: FaceswapModules.pageLoading, isLoading: false }));
}

function* handleGetFaceswapServerFail() {
  toast.error("Failed to get generated video");
  yield put(updateFaceswapLoading({ module: FaceswapModules.faceswap, isLoading: false }));
  yield put(updateFaceswapLoading({ module: FaceswapModules.pageLoading, isLoading: false }));
}

function* handleGetFaceswapImageFromTextServer() {
  yield put(updateFaceswapLoading({ module: FaceswapModules.source, isLoading: true }));
}

function* handleGetFaceswapImageFromTextServerSuccess() {
  yield put(updateFaceswapLoading({ module: FaceswapModules.source, isLoading: false }));
}

function* handleGetFaceswapImageFromTextServerFail(action: any) {
  yield toast.error(action.error.response.data.message || "An error occurred while generating the image.");
  yield put(updateFaceswapLoading({ module: FaceswapModules.source, isLoading: false }));
}

function* handleDeleteFaceswapProjectFail(action: any) {
  yield toast.error(action.error.response.data.message || "Error while deleting project");
  yield put(updatePopup({ popup: Popups.confirmationPopup, status: false }));
}

function* handleDeleteFaceswapProjectSuccess(action: any) {
  const { typeId } = action.meta.previousAction.payload;

  yield put(clearFaceswapList());

  if (typeId) {
    yield put(getAIServiceListServer({ serviceTypeID: typeId, isLoading: true }));
  } else {
    yield put(getFaceswapListServer({ isLoading: true }));
  }

  yield put(updatePopup({ popup: Popups.confirmationPopup, status: false }));
}

export default faceswapSaga;
