import styled, { useTheme } from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../Modal/Modal";
import Soundtrack from "../Sountrack/Soundtrack";
import CircularProgress from "../Icons/CircularProgress";
import Button, { ButtonThemes } from "../Button/Button";
import Textfield, { TextfieldVariant } from "../Textfield/Textfield";
import SelectVideoTranslation, { CustomSelectOptions } from "../Select/SelectVideoTranslaction";
import { Voice } from "../../types/voice";
import { Oauth } from "../../redux/actions/authActions";
import { getOauth } from "../../redux/reducers/authReducer";
import { languages } from "../../forms/ai-voice-cloning/languages";
import { cloneVoiceServer } from "../../redux/actions/profileActions";
import { CheckIconThick, DropdownIconThin, InfoIcon, MicrophoneIcon } from "../Icons/Icons";
import { getCloneVoiceLoading, getProfile } from "../../redux/reducers/profileReducer";

const SAMPLES_LENGTH = 15;
const ACCEPTED_FORMATS = "audio/mp3, audio/wav";
const MAX_SAMPLE_SIZE = 157286400;

interface Props {
  open: boolean;
  onClose: () => void;
}

const AddVoiceAudioPopup = ({ open, onClose }: Props) => {
  const theme: any = useTheme();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [title, setTitle] = useState("");
  const [voices, setVoices] = useState<Voice[]>([]);
  const [checkBox, setCheckBox] = useState<Record<string, boolean>>({
    forChatGPT: false,
    confirmRights: false,
  });
  const [activeSoundtrack, setActiveSoundtrack] = useState<number>(0);
  const [errorSizeSample, setErrorSizeSample] = useState<boolean>(false);
  const [errorVoiceOutOfName, setErrorVoiceOutOfName] = useState<boolean>(false);
  const [errorVoiceNotUploaded, setVoiceNotUploaded] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const cloneVoiceLoading = useSelector(getCloneVoiceLoading);
  const { redirect_uri, state } = useSelector(getOauth);
  const { userToken, hasMultilingual } = useSelector(getProfile);

  const customOptions: CustomSelectOptions = {
    borderRadius: "9px",
    borderRadiusList: "19px",
    border: "1px solid #9E9EAE42",
    borderHover: "1px solid #9E9EAE42",
    backgroundColor: theme.primaryBackground,
    textColor: theme.primaryText,
    textColorActive: "#0063B4",
  };

  const langOptions = hasMultilingual
    ? [
        {
          label: "Multilingual",
          value: "Multilingual",
          icon: "/images/icons/multi.svg",
          endAddornment: <CheckIconThick />,
        },
        ...languages,
      ]
    : [...languages];

  const [language, setLanguage] = useState<string>(langOptions[0].value);

  const handleClose = () => {
    onClose();

    if (redirect_uri && state) {
      window.location.href = `${redirect_uri}?state=${state}&code=${userToken}`;
      dispatch(Oauth({ redirect_uri: "", state: "" }));
      return;
    }
  };

  const handleCheckBoxChange = (name: string) => {
    setCheckBox((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    errorVoiceOutOfName && setErrorVoiceOutOfName(false);
    setTitle(e.target.value);
  };

  const handleLanguageSelect = (value: string) => {
    setLanguage(value);
  };

  const sampleUpload = ({ target }: any) => {
    errorVoiceNotUploaded && setVoiceNotUploaded(false);

    const uploadedFiles = target.files;
    let sizeError = false;

    for (let i = 0; i < uploadedFiles.length; i++) {
      if (uploadedFiles[i].size > MAX_SAMPLE_SIZE) {
        sizeError = true;
      }
    }

    if (sizeError) {
      setErrorSizeSample(true);
      return;
    } else {
      const files = Array.from(uploadedFiles).map((uploadedFile: any) => ({
        id: Math.random(),
        name: uploadedFile.name,
        voice: URL.createObjectURL(uploadedFile),
        file: uploadedFile,
      }));

      setErrorSizeSample(false);
      setVoices([...voices, ...files]);
    }
  };

  const handlePlayingSoundtrack = (id: number) => {
    setActiveSoundtrack(id - 1);
  };

  const deleteVoice = (id: number) => {
    const newVoices = voices.filter(({ id: voiceId }: Voice) => voiceId !== id);

    setVoices(newVoices);

    if (newVoices.length === 0) {
      setVoiceNotUploaded(true);
    }
  };

  const addVoices = () => {
    if (!title) {
      setErrorVoiceOutOfName(true);
      toast.error("Please add a name to the file to continue");
      return;
    }
    if (voices.length === 0) {
      setVoiceNotUploaded(true);
      toast.error("No audio was uploaded");
      return;
    }

    const formData = new FormData();

    [].forEach.call(voices, function (file: Voice) {
      if (!file.file) return;
      formData.append("File", file.file);
    });

    formData.append("name", title);

    if (!language.includes("Multilingual")) formData.append("Language", language);
    formData.append("ForChatGPT", checkBox.forChatGPT.toString());

    dispatch(cloneVoiceServer(formData, title, checkBox.forChatGPT));
  };

  useEffect(() => {
    const cloneParam = searchParams.get("clone");

    if (cloneParam?.toLowerCase() === "true") {
      setCheckBox({ ...checkBox, forChatGPT: true });
    }
  }, [searchParams]);

  return (
    <Modal
      title="Add custom voice"
      className="addVoiceAudio"
      open={open}
      onClose={handleClose}
      maxWidth={660}
      maxHeight={1069}
    >
      <Content>
        <VoiceName error={errorVoiceOutOfName}>
          <Textfield
            label="Voice Name"
            variant={TextfieldVariant.noneAdornment}
            value={title}
            placeholder="Untitled"
            onChange={handleTitleChange}
            required
          />
        </VoiceName>
        <LanguageWrapper>
          <p>Select Language</p>
          <SelectWrapper>
            <SelectVideoTranslation
              placeholder="Select Language"
              value={language}
              onChange={(value: any) => handleLanguageSelect(value)}
              optionsList={langOptions}
              customOptions={customOptions}
              actionIcon={<DropdownIconThin />}
            />
          </SelectWrapper>
        </LanguageWrapper>
        <MainContent>
          <SoundtrackHeader length={voices.length}>
            <span>Samples</span>
            <span>
              {voices.length}/{SAMPLES_LENGTH}
            </span>
          </SoundtrackHeader>
          {voices.length > 0 && (
            <SoundtrackWrapper>
              {voices.map(({ name, voice, id }: Voice) => (
                <Soundtrack
                  key={id}
                  active={id === activeSoundtrack + 1}
                  title={name}
                  soundtrack={voice}
                  variant="addVoiceAudioPopup"
                  handleDelete={() => deleteVoice(id)}
                  onClick={() => handlePlayingSoundtrack(id)}
                />
              ))}
            </SoundtrackWrapper>
          )}
          {voices.length < SAMPLES_LENGTH && (
            <DragAndDropWrapper error={errorVoiceNotUploaded}>
              <input type="file" ref={inputRef} multiple accept={ACCEPTED_FORMATS} onChange={sampleUpload} />
              <MicrophoneIcon />
              <h3>
                Drag and drop your audio, or browse <span>audio</span>
              </h3>
              <SampleFormat>File Supported: WAV, MP3. </SampleFormat>
              <SampleFormat sizeError={errorSizeSample}>Maximum size: 5 MB</SampleFormat>
            </DragAndDropWrapper>
          )}
        </MainContent>
        <InformationWrapper>
          <InfoIcon />
          <span>
            Quantity is less important than quality. The results may be poor if the samples are noisy. Providing more
            than 5 minutes of audio doesn’t make a significant difference.
          </span>
        </InformationWrapper>
        <ConfirmWrapper>
          <input
            type="checkbox"
            checked={checkBox.forChatGPT}
            onChange={() => handleCheckBoxChange("forChatGPT")}
            required
          />
          <span>For ChatGPT</span>
        </ConfirmWrapper>
        <ConfirmWrapper error={!checkBox.confirmRights}>
          <input
            className="required-checkbox"
            type="checkbox"
            checked={checkBox.confirmRights}
            onChange={() => handleCheckBoxChange("confirmRights")}
            required
          />
          <span>
            I confirm that I have all rights or consents to upload and clone these voice samples, and that the
            platform-generated content will not be used for illegal, fraudulent, or harmful purposes. As a customer, I
            agree to abide by Synthesys's{" "}
            <a href="https://synthesys.io/ai-terms/" target="_blank" rel="noreferrer">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="https://synthesys.io/ai-privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy.
            </a>
          </span>
        </ConfirmWrapper>
        <ActionsWrapper>
          <Button text="Cancel" onClick={handleClose} buttonTheme={ButtonThemes.Outline} />
          <Button
            disabled={cloneVoiceLoading || !checkBox.confirmRights}
            text={cloneVoiceLoading ? <CircularProgress /> : "Add"}
            onClick={addVoices}
          />
        </ActionsWrapper>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 16px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const VoiceName = styled.div<{ error: boolean }>`
  input {
    border: 1px solid ${({ theme }) => theme.tableDivider};
    box-shadow: none;
    border-radius: 9px;
    padding: 7px 16px;

    ${({ error }) =>
      error &&
      `
    border: 1px solid #dc2020;
    `}
  }
`;

const SoundtrackHeader = styled.div<{ length: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    color: ${({ theme }) => theme.primaryText};
    opacity: 0.4;
  }

  ${({ length }) =>
    length > 0 &&
    `
      span:last-of-type {
        opacity: 1;
      }
  `}
`;

const LanguageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: 100%;

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.primaryText};
  }
`;

const SelectWrapper = styled.div`
  width: 100%;
  margin-bottom: 7px;
  height: 42px;
  position: relative;

  & > div {
    & > div {
      height: 40px;
    }

    & span > div {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }
  }
  svg {
    // border: 1px solid #35354a;
    width: 18px;
    height: 18px;

    path {
      fill: none;
      stroke: #ffffff;
    }
  }

  :hover > .language-tooltip {
    opacity: 1;
    visibility: visible;
    padding: 5px 6px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    min-width: max-content;
    box-shadow: none;

    span {
      font-size: 10px !important;
      line-height: 12px !important;
      text-transform: none !important;
    }
  }
`;

const DragAndDropWrapper = styled.div<{ error: boolean }>`
  position: relative;
  background: ${({ theme }) => theme.primaryBackground};
  border: 1px dashed #c2c2c2;
  border-radius: 20px;
  height: 187px;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  min-height: 115px;

  ${({ error }) =>
    error &&
    `
    border: 1px solid #dc2020;
    `}

  & > h3 {
    font-family: "Mulish";
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.primaryText};
    margin-top: 4px;
    text-align: center;

    & > span {
      background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  & > input {
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
  }

  @media (max-height: 775px) {
    height: 100px;
    gap: 4px;

    & > svg {
      width: 24px;
      height: 24px;
    }

    & > h3 {
      font-size: 14px;
      margin-top: 0;
    }
  }

  @media (max-width: 500px) {
    height: 130px;
    gap: 4px;

    & > svg {
      width: 24px;
      height: 24px;
    }

    & > h3 {
      font-size: 12px;
      margin-top: 0;
    }
  }
`;

const SampleFormat = styled.p<{ sizeError?: boolean }>`
  font-family: "Mulish";
  font-weight: 400;
  font-size: 12px;
  line-height: 155%;
  color: ${({ theme }) => theme.primaryText};
  opacity: 0.4;
  text-align: center;

  ${({ sizeError }) =>
    sizeError &&
    `
       color: red;
  `}

  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

const InformationWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  svg {
    flex-shrink: 0;
  }

  span {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
    color: ${({ theme }) => theme.primaryText};
    opacity: 0.6;
  }

  @media (max-width: 520px) and (max-height: 775px) {
    span {
      font-size: 8px;
    }
  }
`;

const ConfirmWrapper = styled.label<{ error?: boolean }>`
  display: flex;
  align-items: start;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  a {
    color: ${({ theme }) => theme.primaryText};
    text-decoration: underline;
  }

  input {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    border: 1px solid transparent;

    ${({ error }) =>
      error &&
      `
      -webkit-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
      -moz-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
      box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
    `}
  }

  input[type="checkbox"] {
    background-color: transparent;
  }

  span {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
    color: ${({ theme }) => theme.primaryText};
    opacity: 0.6;
  }

  @media (max-width: 520px) and (max-height: 775px) {
    span {
      font-size: 8px;
    }
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 18px;
  gap: 24px;

  button {
    width: 100%;
    height: 52px;

    svg {
      display: block;
    }
  }

  @media (min-width: 950px) and (max-height: 720px) {
    height: auto;
    margin-top: 0;

    button {
      max-height: 48px;

      & > span {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
`;

const SoundtrackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 4px 0;
  overflow: hidden auto;
  max-height: 200px;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-height: 650px) {
    max-height: 120px;
  }

  @media (max-width: 520px) and (max-height: 775px) {
    max-height: 120px;
  }
`;

export default AddVoiceAudioPopup;
