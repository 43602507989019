import { toast } from "react-toastify";
import { AIServiceProject, FaceswapModules, FaceswapProject } from "../../types/faceSwapType";
import { StoreType } from "../../types/store";
import {
  ADD_MEDIA_TO_SOURCE,
  ADD_MEDIA_TO_TARGET,
  CLEAR_FACESWAP,
  CLEAR_FACESWAP_LIST,
  CLEAR_IMAGE_BY_TYPE,
  CREATE_AI_SERVICE_SERVER,
  CREATE_FACESWAP_SERVER,
  DELETE_AI_SERVICE_PROJECT_SERVER,
  DELETE_FACESWAP_PROJECT_SERVER,
  GENERATE_AUDIO_SERVER,
  GET_AI_SERVICE_BY_ID_SERVER,
  GET_AI_SERVICE_LIST_SERVER,
  GET_FACESWAP_IMAGE_FROM_TEXT_SERVER,
  GET_FACESWAP_LIST_SERVER,
  GET_FACESWAP_SERVER,
  SET_PERCENT,
  SET_TEXT_FOR_AUDIO,
  SET_TEXT_FOR_IMAGE,
  SET_VOICE_SELECT,
  UPDATE_FACESWAP_LOADING,
  UPLOAD_FILE_SERVER,
} from "../actions/faceswapActions";
import Navigate from "../../lib/Navigate";
import { pages } from "../../lib/routeUtils";

export interface faceswapStateType {
  [FaceswapModules.target]: {
    data: string;
    isLoading: boolean;
  };
  [FaceswapModules.source]: {
    data: string;
    isLoading: boolean;
  };
  [FaceswapModules.faceswap]: {
    data: FaceswapProject | null;
    isLoading: boolean;
  };
  [FaceswapModules.pageLoading]: {
    isLoading: boolean;
  };
  [FaceswapModules.faceswapList]: {
    data: FaceswapProject[];
    isLoading: boolean;
    isDeleteLoading: boolean;
  };
  [FaceswapModules.audioFromText]: {
    text: string;
    actorid: string | number;
  };
  [FaceswapModules.imageFromText]: {
    text: string;
  };
  [FaceswapModules.percent]: {
    percent: number;
    type: string;
    projectId: number;
  };
}

const faceswapInitialState: faceswapStateType = {
  [FaceswapModules.target]: {
    data: "",
    isLoading: false,
  },
  [FaceswapModules.source]: {
    data: "",
    isLoading: false,
  },
  [FaceswapModules.faceswap]: {
    data: null,
    isLoading: false,
  },
  [FaceswapModules.pageLoading]: {
    isLoading: false,
  },
  [FaceswapModules.faceswapList]: {
    data: [],
    isLoading: false,
    isDeleteLoading: false,
  },
  [FaceswapModules.audioFromText]: {
    text: "",
    actorid: "",
  },
  [FaceswapModules.imageFromText]: {
    text: "",
  },
  [FaceswapModules.percent]: {
    percent: 0,
    type: "",
    projectId: 0,
  },
};

const faceswapReducer = (state = faceswapInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_FACESWAP_LOADING: {
      const { module, isLoading } = action.payload;
      return { ...state, [module]: { ...state[module as FaceswapModules], isLoading } };
    }
    case UPLOAD_FILE_SERVER: {
      const { type }: { type: FaceswapModules } = action.payload;

      if (!type) return { ...state };

      return {
        ...state,
        [type]: {
          ...state[type],
          data: "",
        },
      };
    }

    case `${UPLOAD_FILE_SERVER}_SUCCESS`: {
      const { file: data } = action.payload.data;
      const { type }: { type: FaceswapModules } = action.meta.previousAction.payload;

      if (!type) return { ...state };

      return {
        ...state,
        [type]: {
          ...state[type],
          data,
        },
      };
    }

    case CLEAR_IMAGE_BY_TYPE: {
      const { type }: { type: FaceswapModules } = action.payload;

      if (!type) return { ...state };

      return {
        ...state,
        [type]: {
          ...state[type],
          data: "",
        },
      };
    }

    case `${CREATE_FACESWAP_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      return {
        ...state,
        [FaceswapModules.faceswap]: {
          ...state[FaceswapModules.faceswap],
          data,
        },
      };
    }

    case `${CREATE_AI_SERVICE_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      return {
        ...state,
        [FaceswapModules.faceswap]: {
          ...state[FaceswapModules.faceswap],
          data: {
            faceswapProjectId: data?.aiServiceID,
            userId: data?.userID,
            title: data?.title,
            faceswapType: "",
            source: data?.input.image,
            target: data?.input?.video || data?.input?.audio,
            insertDateTime: data?.insertDateTime,
            updateDateTime: data?.updateDateTime,
            isDeleted: data?.isDeleted,
            estimatedTime: data?.estimatedTime,
            status: data?.status,
            outputUrl: data?.output,
            totalSecondsTaken: data?.totalSecondsTaken,
            completeDateTime: data?.completeDateTime,
            estimatedCompleteDateTime: data?.estimatedCompleteDateTime,
            progress: data?.progress,
            guid: data?.guid,
            coverImage: data?.coverImage,
            serviceTypeID: data?.serviceTypeID,
            input: data?.input,
          },
        },
      };
    }

    case `${GET_AI_SERVICE_BY_ID_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;
      const { isProjectRestored } = action.meta.previousAction.payload;

      const faceswapList = state.faceswapList.data?.map((faceswap) =>
        faceswap.faceswapProjectId === data.aiServiceID ? { ...faceswap, status: data.status } : faceswap,
      );

      if (isProjectRestored && (data.status === 1 || data.status === 2)) {
        toast.info("The project is being generated, to view wait until the end of generation!");
        Navigate.push(`${data.serviceTypeID === 1 ? pages.transform() : pages.talkingAvatar()}`);
        return {
          ...state,
          [FaceswapModules.faceswap]: { ...state[FaceswapModules.faceswap], data: null, isLoading: false },
        };
      }

      if (data.status === 4) {
        return { ...state, [FaceswapModules.faceswap]: { ...state[FaceswapModules.faceswap], data: null } };
      }

      return {
        ...state,
        [FaceswapModules.faceswap]: {
          ...state[FaceswapModules.faceswap],
          data: {
            faceswapProjectId: data?.aiServiceID,
            userId: data?.userID,
            title: data?.title,
            faceswapType: "",
            source: data?.input.image,
            target: data?.input?.video || data?.input?.audio,
            insertDateTime: data?.insertDateTime,
            updateDateTime: data?.updateDateTime,
            isDeleted: data?.isDeleted,
            estimatedTime: data?.estimatedTime,
            status: data?.status,
            outputUrl: data?.output,
            totalSecondsTaken: data?.totalSecondsTaken,
            completeDateTime: data?.completeDateTime,
            estimatedCompleteDateTime: data?.estimatedCompleteDateTime,
            progress: data?.progress,
            guid: data?.guid,
            coverImage: data?.coverImage,
            serviceTypeID: data?.serviceTypeID,
            input: data?.input,
          },
        },
        [FaceswapModules.faceswapList]: { ...state[FaceswapModules.faceswapList], data: faceswapList },
      };
    }

    case `${GET_FACESWAP_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;
      const { isProjectRestored } = action.meta.previousAction.payload;

      const faceswapList = state.faceswapList.data?.map((faceswap) =>
        faceswap.faceswapProjectId === data.faceswapProjectId ? { ...faceswap, status: data.status } : faceswap,
      );

      if (isProjectRestored && (data.status === 1 || data.status === 2)) {
        toast.info("The project is being generated, to view wait until the end of generation!");
        Navigate.push(`${pages.faceswap()}`);
        return {
          ...state,
          [FaceswapModules.faceswap]: { ...state[FaceswapModules.faceswap], data: null, isLoading: false },
        };
      }

      if (data.status === 4) {
        return { ...state, [FaceswapModules.faceswap]: { ...state[FaceswapModules.faceswap], data: null } };
      }

      return {
        ...state,
        [FaceswapModules.faceswap]: { ...state[FaceswapModules.faceswap], data },
        [FaceswapModules.faceswapList]: { ...state[FaceswapModules.faceswapList], data: faceswapList },
      };
    }

    case `${GET_AI_SERVICE_LIST_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      const faceswapList = data.map((item: AIServiceProject) => ({
        faceswapProjectId: item?.aiServiceID,
        userId: item?.userID,
        title: item?.title,
        faceswapType: "",
        source: item?.input?.image,
        target: item?.input?.video || item?.input?.audio,
        insertDateTime: item?.insertDateTime,
        updateDateTime: item?.updateDateTime,
        isDeleted: item?.isDeleted,
        estimatedTime: item?.estimatedTime,
        status: item?.status,
        outputUrl: item?.output,
        totalSecondsTaken: item?.totalSecondsTaken,
        completeDateTime: item?.completeDateTime,
        estimatedCompleteDateTime: item?.estimatedCompleteDateTime,
        progress: item?.progress,
        guid: item?.guid,
        coverImage: item?.coverImage,
        serviceTypeID: item?.serviceTypeID,
        input: item?.input,
      }));

      const faceswap =
        faceswapList?.find(
          (faceswap: FaceswapProject) => faceswap.faceswapProjectId === state.faceswap.data?.faceswapProjectId,
        ) || state.faceswap.data;

      const newData = {
        data: faceswap,
        isLoading: faceswap?.status === 3 || faceswap?.status === 4 ? false : state.faceswap.isLoading,
      };

      return {
        ...state,
        [FaceswapModules.faceswapList]: {
          ...state[FaceswapModules.faceswapList],
          data: faceswapList,
        },
        [FaceswapModules.faceswap]: {
          ...state[FaceswapModules.faceswap],
          ...newData,
        },
      };
    }

    case `${GET_FACESWAP_LIST_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      const faceswap =
        data?.find(
          (faceswap: FaceswapProject) => faceswap.faceswapProjectId === state.faceswap.data?.faceswapProjectId,
        ) || state.faceswap.data;

      const newData = {
        data: faceswap,
        isLoading: faceswap?.status === 3 || faceswap?.status === 4 ? false : state.faceswap.isLoading,
      };

      return {
        ...state,
        [FaceswapModules.faceswapList]: {
          ...state[FaceswapModules.faceswapList],
          data,
        },
        [FaceswapModules.faceswap]: {
          ...state[FaceswapModules.faceswap],
          ...newData,
        },
      };
    }

    case CLEAR_FACESWAP: {
      return {
        ...state,
        [FaceswapModules.target]: { ...state[FaceswapModules.target], data: "" },
        [FaceswapModules.source]: { ...state[FaceswapModules.source], data: "" },
        [FaceswapModules.audioFromText]: { ...state[FaceswapModules.audioFromText], text: "", actorid: "" },
        [FaceswapModules.imageFromText]: { ...state[FaceswapModules.imageFromText], text: "" },
        [FaceswapModules.faceswap]: { ...state[FaceswapModules.faceswap], data: null, isLoading: false },
      };
    }

    case ADD_MEDIA_TO_TARGET: {
      const { url } = action.payload;

      return {
        ...state,
        [FaceswapModules.target]: {
          ...state[FaceswapModules.target],
          data: url,
        },
      };
    }

    case ADD_MEDIA_TO_SOURCE: {
      const { url } = action.payload;

      return {
        ...state,
        [FaceswapModules.source]: {
          ...state[FaceswapModules.source],
          data: url,
        },
      };
    }

    case `${GENERATE_AUDIO_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      return {
        ...state,
        [FaceswapModules.target]: {
          ...state[FaceswapModules.target],
          data: data?.[0]?.outputUrl,
        },
      };
    }

    case SET_TEXT_FOR_AUDIO: {
      const { text } = action.payload;

      return {
        ...state,
        [FaceswapModules.audioFromText]: {
          ...state[FaceswapModules.audioFromText],
          text,
        },
      };
    }

    case SET_VOICE_SELECT: {
      const { actorid } = action.payload;

      return {
        ...state,
        [FaceswapModules.audioFromText]: {
          ...state[FaceswapModules.audioFromText],
          actorid,
        },
      };
    }

    case `${GET_FACESWAP_IMAGE_FROM_TEXT_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      return {
        ...state,
        [FaceswapModules.source]: { ...state[FaceswapModules.source], data: data?.[0]?.path },
      };
    }

    case SET_TEXT_FOR_IMAGE: {
      const { text } = action.payload;

      return {
        ...state,
        [FaceswapModules.imageFromText]: {
          ...state[FaceswapModules.imageFromText],
          text,
        },
      };
    }

    case SET_PERCENT: {
      const { percent, type, projectId } = action.payload;

      return {
        ...state,
        [FaceswapModules.percent]: {
          ...state[FaceswapModules.percent],
          percent,
          type,
          projectId,
        },
      };
    }

    case DELETE_FACESWAP_PROJECT_SERVER:
    case DELETE_AI_SERVICE_PROJECT_SERVER: {
      return {
        ...state,
        [FaceswapModules.faceswapList]: {
          ...state[FaceswapModules.faceswapList],
          isDeleteLoading: true,
        },
      };
    }

    case `${DELETE_FACESWAP_PROJECT_SERVER}_FAIL`:
    case `${DELETE_FACESWAP_PROJECT_SERVER}_SUCCESS`:
    case `${DELETE_AI_SERVICE_PROJECT_SERVER}_FAIL`:
    case `${DELETE_AI_SERVICE_PROJECT_SERVER}_SUCCESS`: {
      return {
        ...state,
        [FaceswapModules.faceswapList]: {
          ...state[FaceswapModules.faceswapList],
          isDeleteLoading: false,
        },
      };
    }

    case CLEAR_FACESWAP_LIST: {
      return {
        ...state,
        [FaceswapModules.faceswapList]: {
          ...state[FaceswapModules.faceswapList],
          data: [],
        },
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const getFaceswapTarget = (store: StoreType) => store.faceswap[FaceswapModules.target].data;
export const getFaceswapTargetLoading = (store: StoreType) => store.faceswap[FaceswapModules.target].isLoading;

export const getFaceswapSource = (store: StoreType) => store.faceswap[FaceswapModules.source].data;
export const getFaceswapSourceLoading = (store: StoreType) => store.faceswap[FaceswapModules.source].isLoading;

export const getFaceswap = (store: StoreType) => store.faceswap[FaceswapModules.faceswap].data;
export const getFaceswapLoading = (store: StoreType) => store.faceswap[FaceswapModules.faceswap].isLoading;

export const getFaceswapList = (store: StoreType) => store.faceswap[FaceswapModules.faceswapList].data;
export const getFaceswapListLoading = (store: StoreType) => store.faceswap[FaceswapModules.faceswapList].isLoading;
export const getIsFaceswapDeleteLoading = (state: StoreType) =>
  state.faceswap[FaceswapModules.faceswapList].isDeleteLoading;

export const getTextForAudio = (store: StoreType) => store.faceswap[FaceswapModules.audioFromText].text;
export const getActorIdForAudio = (store: StoreType) => store.faceswap[FaceswapModules.audioFromText].actorid;

export const getTextForImage = (store: StoreType) => store.faceswap[FaceswapModules.imageFromText].text;

export const getPercent = (store: StoreType) => store.faceswap[FaceswapModules.percent];

export const getFaceswapPageLoading = (store: StoreType) => store.faceswap[FaceswapModules.pageLoading].isLoading;

export default faceswapReducer;
