import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import PlayPauseButton from "./PlayPauseButton";
import MenuItem from "../../../components/ActionMenu/MenuItem";
import ActionMenu from "../../../components/ActionMenu/ActionMenu";
import CircularProgress from "../../../components/Icons/CircularProgress";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import { downloadFile } from "../../../lib/downloadFile";
import { parsePercent } from "../../../lib/parsePercent";
import { formatDateString } from "../../../lib/formatDateString";
import { VideoTranslationStatus } from "../../../types/videoTranslation";
import { DownloadIcon, OptionsIcon, TrashIcon } from "../../../components/Icons/Icons";
import Button, { ButtonThemes } from "../../../components/Button/Button";
import { ShuffleIcon } from "../../../components/Icons/ShuffleIcon";
import { useDispatch } from "react-redux";
import {
  addMediaToSource,
  addMediaToTarget,
  clearFaceswap,
  setTextForAudio,
  setTextForImage,
} from "../../../redux/actions/faceswapActions";
import { Input } from "../../../types/faceSwapType";
import { useSelector } from "react-redux";
import { getPercent } from "../../../redux/reducers/faceswapReducer";
import { ConfirmationDelete, DeleteType } from "../../../types/confirmationDelete";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";

function isImageUrl(url: string): boolean {
  return /\.(jpeg|jpg|png)$/i.test(url);
}

interface Props {
  type: string;
  title: string;
  status: VideoTranslationStatus;
  insertDateTime: string;
  estimatedTime: number;
  faceswapProjectId: number;
  faceswapType: string;
  outputUrl: string;
  source: string;
  target: string;
  input: Input;
  coverImage: string;
  serviceTypeID: number;
}

const MyLibraryBarItem = ({
  type,
  title,
  status,
  insertDateTime,
  estimatedTime,
  faceswapProjectId,
  outputUrl,
  coverImage,
  serviceTypeID,
  faceswapType,
  source,
  target,
  input,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loadedVideos, setLoadedVideos] = useState<any>(true);
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const percentServer = useSelector(getPercent);

  const videoRef = useRef(document.createElement("video"));
  const [playing, setPlaying] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);

  const [menuOpen, setMenuOpen] = useState<number | null>(null);
  const menuOptions = ["Download", "Delete"];

  const recogniseIcon = (name: string) => {
    switch (name) {
      case "Download":
        return <DownloadIcon />;
      case "Delete":
        return <TrashIcon />;
      default:
        return <></>;
    }
  };

  const actionMenu = [
    {
      icon: <OptionsIcon />,
      options: menuOptions?.map((option) => ({
        name: option,
        icon: recogniseIcon(option),
      })),
    },
  ];

  const statusText = {
    [VideoTranslationStatus.Pending]: "Pending...",
    [VideoTranslationStatus.InProgress]: "Generating...",
    [VideoTranslationStatus.Completed]: "Completed",
    [VideoTranslationStatus.Failed]: "Failed To Generate",
  };

  const lastUpdateTime = formatDateString(insertDateTime);
  const timeElapsed = (Number(currentTime) - Number(lastUpdateTime)) / 1000;
  const percentComplete = (timeElapsed / Number(estimatedTime)) * 100;

  const percent =
    type === percentServer?.type?.replace(" ", "").replace(/^./, percentServer?.type?.[0]?.toLowerCase()) &&
    faceswapProjectId === percentServer?.projectId
      ? percentServer?.percent
      : percentComplete;

  const handleVideoLoad = () => setLoadedVideos(false);

  const togglePlayed = () => {
    setIsPlayed(!isPlayed);
    setPlaying(false);
    videoRef.current.currentTime = 0;
  };

  const togglePlay = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setPlaying(!playing);
    if (playing === true) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  const handleOpenMenu = (e: any, id: number) => {
    e.preventDefault();
    e.stopPropagation();

    setMenuOpen(menuOpen === id ? null : id);
  };

  const handleCloseMenu = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setMenuOpen(null);
  };

  const handleRemix = () => {
    dispatch(clearFaceswap());

    switch (type) {
      case "transform":
        dispatch(addMediaToSource(source));
        dispatch(addMediaToTarget(input.video as string));
        break;
      case "talkingAvatar":
        dispatch(addMediaToSource(source));
        dispatch(addMediaToTarget(input.audio as string));
        break;
      case "faceswap":
        dispatch(addMediaToSource(source));
        dispatch(addMediaToTarget(target));
        break;
      case "replicateMedia":
        dispatch(addMediaToTarget(input.video as string));
        dispatch(setTextForAudio(input.prompt as string));
        break;
      default:
        console.log("Type: default");
        break;
    }
  };

  const handleDeleteClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (!faceswapProjectId) {
      throw Error("Project entity is missing while deleting");
    }

    const prefilled: ConfirmationDelete = {
      id: faceswapProjectId,
      title: "Are you sure you want to delete project " + title,
      description: "The project will be removed and the action cannot be undone",
      typeId: type === "faceswap" ? 0 : serviceTypeID,
      type: type === "faceswap" ? DeleteType.Faceswap : DeleteType.AIService,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  useEffect(() => {
    if (status === VideoTranslationStatus.InProgress || status === VideoTranslationStatus.Pending) {
      const intervalId = setInterval(() => setCurrentTime(new Date()), 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [status]);

  const translationStatus = statusText[status as VideoTranslationStatus];
  const isPending = status === VideoTranslationStatus.InProgress || status === VideoTranslationStatus.Pending;
  const isCompleted = status === VideoTranslationStatus.Completed;
  const hasFailed = status === VideoTranslationStatus.Failed;

  return (
    <Wrapper>
      {hasFailed && (
        <StatusWrapper>
          <span>
            {translationStatus}
            <p>project #{faceswapProjectId}</p>
          </span>
          <ActionWrapper>
            <ActionMenu
              position={{
                x: "left",
                y: "bottom",
              }}
              open={menuOpen === faceswapProjectId}
              menuStyle={{ top: "0px", right: "40px", padding: "4px 4px 4px 12px" }}
              handleClose={handleCloseMenu}
              trigger={
                <IconButton
                  iconButtonTheme={IconButtonThemes.Transparent}
                  icon={<OptionsIcon />}
                  onClick={(e: any) => handleOpenMenu(e, faceswapProjectId)}
                />
              }
            >
              <MenuWrapper>
                <MenuItem
                  icon={<TrashIcon />}
                  onClick={(e: any) => {
                    e.preventDefault();
                    handleDeleteClick(e);
                  }}
                >
                  Delete
                </MenuItem>
              </MenuWrapper>
            </ActionMenu>
          </ActionWrapper>
        </StatusWrapper>
      )}
      {isPending && (
        <>
          {coverImage ? <PlaceholderImage src={coverImage} /> : <Placeholder />}
          <ProgressBarOuterWrapper>
            <ProgressBarInnerWrapper>
              <span>{parsePercent(percent < 1 ? 1 : percent)}%</span>
            </ProgressBarInnerWrapper>
          </ProgressBarOuterWrapper>
        </>
      )}
      {isCompleted && (
        <>
          {isImageUrl(outputUrl) ? (
            <MediaWrapper>
              <img src={outputUrl} onLoad={handleVideoLoad} style={{ display: loadedVideos ? "none" : "block" }} />
              {loadedVideos && <CircularProgress color="#fff" />}
            </MediaWrapper>
          ) : (
            <MediaWrapper>
              <video
                ref={videoRef}
                onLoadedData={handleVideoLoad}
                onEnded={togglePlayed}
                style={{ display: loadedVideos ? "none" : "block" }}
              >
                <source src={outputUrl} type="video/mp4" />
              </video>
              {!loadedVideos ? "" : <CircularProgress color="#fff" />}
            </MediaWrapper>
          )}
          {!loadedVideos && (
            <>
              <RemixButton>
                <Button
                  text={"Remix"}
                  icon={<ShuffleIcon />}
                  buttonTheme={ButtonThemes.Transparent}
                  onClick={handleRemix}
                  style={{ zIndex: "1" }}
                />
              </RemixButton>
              {!isImageUrl(outputUrl) && (
                <PlayButtonWrapper>
                  <PlayPauseButton isPlay={playing} handlePlayChange={(e: any) => togglePlay(e)} />
                </PlayButtonWrapper>
              )}
              <ActionWrapper>
                {actionMenu.map((m, order) => (
                  <ActionMenu
                    key={order}
                    position={{
                      x: "left",
                      y: "bottom",
                    }}
                    open={menuOpen === faceswapProjectId}
                    menuStyle={{ top: "0px", right: "40px", padding: "4px 4px 4px 12px" }}
                    handleClose={handleCloseMenu}
                    trigger={
                      <IconButton
                        iconButtonTheme={IconButtonThemes.Transparent}
                        icon={m.icon}
                        onClick={(e: any) => handleOpenMenu(e, faceswapProjectId)}
                      />
                    }
                  >
                    {m.options && (
                      <MenuWrapper>
                        {m.options.map((option, index) => (
                          <MenuItem
                            key={index}
                            icon={option.icon}
                            onClick={(e: any) => {
                              e.preventDefault();
                              option.name === "Download" && downloadFile(e, outputUrl || "");
                              option.name === "Delete" && handleDeleteClick(e);
                            }}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </MenuWrapper>
                    )}
                  </ActionMenu>
                ))}
              </ActionWrapper>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 320px;
  height: 230px;

  @media (max-width: 400px) {
    max-width: 100%;
    max-height: 128px;
    width: 100%;
  }

  &:hover {
    button,
    div {
      display: flex;
    }
  }
`;

const StatusWrapper = styled.div`
  width: 320px;
  height: 230px;
  background: #6d6d6d33;
  padding: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    text-transform: capitalize;

    p {
      max-width: 166px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  @media (max-width: 400px) {
    width: 100%;
    height: 100%;
  }
`;

const PlayButtonWrapper = styled.div`
  & div {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    border-radius: 54.02px;
    angle: -0 deg;
    background: #0286dcba;
    transform: translate(-50%, -50%);
    border: none;
    outline: none;
  }
`;

const RemixButton = styled.div`
  position: absolute;
  width: 112px;
  height: 30px;
  display: none;
  align-items: center;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
  outline: none;
  border: none;
  border-radius: 15px;
  background: #48d64859;
  gap: 10px;
  padding: 0 15px;

  :hover {
    background: #48d64899;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-90deg, #616060, #6d6d6d33);
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;

  @media (max-width: 400px) {
    width: 100%;
    height: 100%;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const ProgressBarOuterWrapper = styled.div`
  width: 320px;
  height: 230px;
  padding: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 400px) {
    width: 100%;
    height: 100%;
  }
`;

const ProgressBarInnerWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0286dce5;
  border-radius: 50%;

  span {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #ffffffeb;
  }

  svg {
    width: 38px;
    height: 38px;

    path {
      stroke: #ffffffeb;
    }
  }
`;

const PlaceholderImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ActionWrapper = styled("div")`
  display: none;
  position: absolute;
  top: 8px;
  right: 8px;
  align-items: center;
  justify-content: center;
  align-items: center;
  background: #0063b4;
  border-radius: 6px;
  width: 25px;
  height: 25px;

  span {
    button {
      width: 25px;
      height: 25px;
      max-width: 25px;
      border: none;
      box-shadow: none;
      border-radius: 6px;

      svg > path {
        fill: ${({ theme }) => theme.tableTitleText};
      }
    }
  }

  .menu-wrapper {
    border: none;
    max-width: 180px;
    background: #0063b4;

    div {
      color: #ffffff;
      -webkit-text-fill-color: unset;
      background: none;
      padding: 3px 0px;
      font-weight: 500;
      svg > path {
        fill: #ffffff;
      }
    }
  }
`;

const MenuWrapper = styled("div")`
  width: 140px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const MediaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 230px;
  background: #6d6d6d33;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default MyLibraryBarItem;
