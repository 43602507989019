import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import VideoCard from "../VideoCard";
import SourceFaceCard from "../SourceFaceCard";
import ManipulateCard from "../ManipulateCard";
import { FaceswapProjectTab } from "../..";
import { MediaIcon } from "../../../../components/Icons/FaceSwapIcons";
import {
  clearFaceswap,
  getAIServiceByIdServer,
  getAIServiceListServer,
} from "../../../../redux/actions/faceswapActions";

const ReplicateMediaScreen = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const [activeFaceTabInRegeneration, setFaceTabInRegeneration] = useState("Video");
  const isImageTab = activeFaceTabInRegeneration === "Image";

  const interval = useRef<NodeJS.Timer | null>(null);

  const fetchData = (isLoading?: boolean) => {
    dispatch(getAIServiceListServer({ serviceTypeID: isImageTab ? 5 : 4, isLoading }));
  };

  useEffect(() => {
    fetchData(true);
    interval.current = setInterval(() => fetchData(false), 10000);
    return () => {
      clearInterval(interval.current!);
    };
  }, []);

  useEffect(() => {
    if (interval && interval.current) {
      clearInterval(interval.current);
      interval.current = setInterval(() => fetchData(false), 10000);
    }
    fetchData(false);
  }, [activeFaceTabInRegeneration]);

  useEffect(() => {
    dispatch(clearFaceswap());

    if (id) dispatch(getAIServiceByIdServer({ id: parseInt(id), isProjectRestored: true }));
  }, [id]);

  return (
    <>
      <SourceFaceCard
        cardTitle="Source"
        cardIcon={<MediaIcon />}
        cardTooltip={
          isImageTab
            ? "Upload any type of image you want to replicate."
            : "Upload any stock video. It works best with horizontal ratio videos."
        }
        activeTab={activeFaceTabInRegeneration}
        setActiveTab={setFaceTabInRegeneration}
        isTextGenerationEnabled={false}
        isTabSwitchEnabled
        type={FaceswapProjectTab["Replicate Media"]}
      />
      <ManipulateCard />
      <VideoCard
        type={FaceswapProjectTab["Replicate Media"]}
        generateButtonText={isImageTab ? "Regenerate image now" : "Regenerate video now"}
        activeTab={activeFaceTabInRegeneration}
      />
    </>
  );
};

export default ReplicateMediaScreen;
