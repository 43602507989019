import styled from "styled-components";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tabs from "./Tabs";
import IdeasForVideo from "./IdeasForVideo";
import PlayPauseButton from "./PlayPauseButton";
import Tooltip from "../../../components/Tooltip/Tooltip";
import CircularProgress from "../../../components/Icons/CircularProgress";
import Button, { ButtonThemes } from "../../../components/Button/Button";
import { FaceswapProjectTab } from "..";
import { isImageUrl } from "../../../lib/isImageUrl";
import { HelpIcon } from "../../../components/Icons/HelpIcon";
import { FolderIcon } from "../../../components/Icons/FolderIcon";
import { CloudArrowUp } from "../../../components/Icons/CloudArrowUp";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { InfoIcon, MediaIcon, UploadBoxIcon } from "../../../components/Icons/FaceSwapIcons";
import { getFaceswap, getFaceswapTarget, getFaceswapTargetLoading } from "../../../redux/reducers/faceswapReducer";
import {
  addMediaToSource,
  addMediaToTarget,
  clearImageByType,
  faceswapUploadFileServer,
} from "../../../redux/actions/faceswapActions";
import TooltipBig from "./TooltipBig";
import { getProfile } from "../../../redux/reducers/profileReducer";

const MAX_SIZE_IMAGE = 3145728;
const MAX_SIZE_VIDEO = 52428800;
const MAX_SIZE_VIDEO_FOR_UNLIMITED = 209715200;
const ACCEPTED_FORMATS_IMAGE = "image/png, image/jpg, image/jpeg";
const ACCEPTED_FORMATS_VIDEO = "video/mp4";

const tabs = [
  {
    label: "Image",
    endAdornment: <InfoIcon />,
    tooltip:
      "Upload the target image. For best results, please avoid images with a half face, side face, blurry face or faces with glasses.",
  },
  {
    label: "Video",
    endAdornment: <InfoIcon />,
    tooltip:
      "Upload the target video. The face should be in all frames of the video. For best results, please avoid images with a half face, side face, blurry face or faces with glasses.",
  },
];

interface Props {
  type: FaceswapProjectTab;
  cardTitle: string;
  activeTab: string;
  setActiveTab: (mediaTab: string) => void;
}

const MediaCard = ({ type, cardTitle, activeTab, setActiveTab }: Props) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [errorSize, setErrorSize] = useState<boolean>(false);
  const [imageLoad, setImageLoad] = useState(true);

  const project = useSelector(getFaceswap);
  const targetUrl = useSelector(getFaceswapTarget);
  const isLoading = useSelector(getFaceswapTargetLoading);
  const { hasUnlimitedFaceswap } = useSelector(getProfile);

  const videoRef = useRef(document.createElement("video"));
  const [playing, setPlaying] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);

  const target = project?.target || targetUrl;
  const isAction = !project || !project?.faceswapProjectId;
  const isImageTab = activeTab === "Image";
  const isUnlimited = hasUnlimitedFaceswap && type === FaceswapProjectTab.Transform;

  const inputVideoDuration = {
    [FaceswapProjectTab.Transform]: "up to 1 minute",
    [FaceswapProjectTab.Faceswap]: "up to 2 minutes",
  };

  const handleInputClick = () => {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  const handleLoad = () => setImageLoad(false);

  const handleDelete = () => {
    setImageLoad(true);
    dispatch(clearImageByType("target"));
  };

  const handleUpload = ({ target }: any) => {
    const uploadedFile = target.files[0];

    if (!uploadedFile) return;

    const allowedTypes = isImageTab ? /image\/(png|jpeg|jpg)/ : /video\/(mp4)/;

    if (
      uploadedFile &&
      uploadedFile.size > (isImageTab ? MAX_SIZE_IMAGE : isUnlimited ? MAX_SIZE_VIDEO_FOR_UNLIMITED : MAX_SIZE_VIDEO)
    ) {
      if (inputRef.current) {
        inputRef.current.value = "";
      }

      setErrorSize(true);
      toast.error(
        `The file must not exceed ${
          isImageTab ? "3MB" : isUnlimited ? "200MB" : "50MB"
        }! Please upload a smaller file.`,
      );
    } else if (!uploadedFile.type.match(allowedTypes)) {
      if (inputRef.current) {
        inputRef.current.value = "";
      }

      setErrorSize(true);
      toast.error(`Incorrect file format! Please upload the file in ${isImageTab ? "PNG or JPG" : "MP4"} format.`);
    } else {
      if (!isImageTab) {
        const video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          const duration = video.duration;

          if (duration > (type === FaceswapProjectTab.Transform ? 60 : 120)) {
            if (inputRef.current) {
              inputRef.current.value = "";
            }

            setErrorSize(true);
            toast.error(
              `The video must be ${
                inputVideoDuration[type as keyof typeof inputVideoDuration]
              }! Please upload a shorter video.`,
            );
          } else {
            const formData = new FormData();
            formData.append("File", uploadedFile);

            setErrorSize(false);
            setImageLoad(true);
            dispatch(faceswapUploadFileServer({ data: formData, type: "target" }));
          }
        };

        video.src = URL.createObjectURL(uploadedFile);
      } else {
        const formData = new FormData();
        formData.append("File", uploadedFile);

        setErrorSize(false);
        setImageLoad(true);
        dispatch(faceswapUploadFileServer({ data: formData, type: "target" }));
      }
    }
  };

  const togglePlayed = () => {
    setIsPlayed(!isPlayed);
    setPlaying(false);
    videoRef.current.currentTime = 0;
  };

  const togglePlay = () => {
    setPlaying(!playing);
    if (playing === true) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  const handleActiveMainTab = (tab: string) => setActiveTab(tab);

  const handlePopupOpen = () => {
    const prefilled = {
      type,
      mediaType: activeTab,
      onClick: (url: string) => {
        dispatch(addMediaToTarget(url));
      },
    };

    dispatch(
      updatePopup({
        popup: Popups.myLibraryPopup,
        status: true,
        prefilled,
      }),
    );
  };

  useEffect(() => {
    setImageLoad(true);
    target && setActiveTab(isImageUrl(target) ? "Image" : "Video");
  }, [target]);

  return (
    <Card>
      <Title>
        <IconWrapper>
          <MediaIcon />
          <Text>{cardTitle}</Text>
        </IconWrapper>
        {type === FaceswapProjectTab.Faceswap && (
          <>
            <TabsWrapper>
              <Tabs
                data={tabs}
                active={activeTab}
                handleActive={handleActiveMainTab}
                disabled={isLoading || !!target}
              />
            </TabsWrapper>
            <Info>
              Guide
              <HelpIcon />
              <Tooltip
                text="Upload the target video. The face should be in all frames of the video. For best results, please avoid images with half face, side face, blurry face or faces with glasses."
                style={{ boxShadow: "none", borderRadius: "7px", padding: "4px 8px 6px" }}
                position="bottom"
                arrow
              />
            </Info>
          </>
        )}
        {type === FaceswapProjectTab.Transform && (
          <Info>
            Guide
            <HelpIcon />
            <TooltipBig type="video" />
          </Info>
        )}
      </Title>
      <DragAndDropWrapper visible={!!target}>
        <CardContent isLoading={!!target && imageLoad}>
          {!isLoading && isAction && (
            <input
              ref={inputRef}
              type="file"
              id="faceswapTarget"
              name="faceswapTarget"
              accept={isImageTab ? ACCEPTED_FORMATS_IMAGE : ACCEPTED_FORMATS_VIDEO}
              onChange={handleUpload}
            />
          )}
          {target ? (
            <>
              {isImageUrl(target) ? (
                <ImageWrapper>
                  <img
                    src={target}
                    height={"100%"}
                    width={"100%"}
                    onLoad={handleLoad}
                    style={{ display: imageLoad ? "none" : "block" }}
                  />
                  {imageLoad && <CircularProgress color="#fff" />}
                </ImageWrapper>
              ) : (
                <VideoWrapper>
                  <video
                    ref={videoRef}
                    src={target}
                    width={"100%"}
                    height="100%"
                    onLoadedData={handleLoad}
                    onEnded={togglePlayed}
                    style={{ display: imageLoad ? "none" : "block" }}
                  />
                  {!imageLoad ? (
                    <PlayPauseButton isPlay={playing} handlePlayChange={() => togglePlay()} />
                  ) : (
                    <CircularProgress color="#fff" />
                  )}
                </VideoWrapper>
              )}
            </>
          ) : (
            <ActionInfo>
              <ActionIcon>
                <CloudArrowUp />
              </ActionIcon>
              <ActionTitle>Drag and drop an {isImageTab ? "image" : "video"} here or click to upload</ActionTitle>
              {isImageTab ? (
                <ActionText isError={errorSize}>File Supported: PNG, JPG. Maximum size: 3MB</ActionText>
              ) : (
                <ActionText isError={errorSize}>
                  File Supported: MP4. Maximum size: {isUnlimited ? "200MB" : "50MB"},{" "}
                  {inputVideoDuration[type as keyof typeof inputVideoDuration]}
                </ActionText>
              )}
            </ActionInfo>
          )}
        </CardContent>
        {isAction && (
          <ButtonsWrapper>
            <Button
              text={"My Library"}
              icon={<FolderIcon />}
              disabled={isLoading}
              buttonTheme={ButtonThemes.Transparent}
              onClick={() => {
                handlePopupOpen();
              }}
              style={{ zIndex: "1" }}
            />
            <Button
              text={isLoading ? "Loading..." : "Upload"}
              icon={<UploadBoxIcon />}
              disabled={isLoading}
              buttonTheme={ButtonThemes.Transparent}
              onClick={handleInputClick}
              style={{ zIndex: "1" }}
            />
            {target && (
              <Button
                text={"Delete"}
                onClick={handleDelete}
                buttonTheme={ButtonThemes.Transparent}
                style={{ zIndex: "1" }}
              />
            )}
          </ButtonsWrapper>
        )}
      </DragAndDropWrapper>
      {(!target || isLoading) && (
        <IdeasForVideo isTransformSamples={cardTitle === "Video"} activeMediaTab={activeTab} />
      )}
    </Card>
  );
};

const Card = styled.div`
  position: relative;
  flex: 3;
  background: #15171a;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  padding: 17px 18px;
  margin-bottom: 18px;
  min-width: 578px;

  @media (max-width: 1200px) {
    min-width: auto;
    padding: 10px 18px 17px;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  gap: 10px;

  & > svg {
    width: 25px;
    height: 25px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & > :first-child {
    width: 32px;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  margin-right: auto;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: 400px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 400px;
  max-width: fit-content;
  align-self: center;

  & video {
    object-fit: contain;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const DragAndDropWrapper = styled.div<{ visible?: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  & > div > input {
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    cursor: pointer;
    ${({ visible }) =>
      visible &&
      `
        z-index: -1;
      `}
  }
`;

const CardContent = styled.div<{ isLoading: boolean }>`
  height: 100%;
  min-height: ${({ isLoading }) => (isLoading ? "357px" : "auto")};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 20px;

  & button {
    height: 30px;
    max-width: 130px;
    padding: 0px 18px;
    gap: 10px;
    border-radius: 50px;
    border: 1px solid #6f7074;
    justify-content: center;

    span {
      text-wrap: nowrap;
    }

    .folder-icon path {
      fill: none;
    }
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  align-self: end;
  margin: 0 0 4px;
  height: 32px;
  border-radius: 52px;
  border: 0px;
  background: #292937;

  & > div {
    background: #292937;
    box-shadow: none;
    z-index: 10;

    & > div {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 52px;
      border: 0px;

      & > div {
        top: 40px;
        left: -40px;
        width: 280px;
        opacity: 0.6;
        border-radius: 7px;
        background: #292937;

        &::after {
          opacity: 0.6;
          border-color: transparent transparent #292937 transparent;
        }
      }
    }

    button {
      box-shadow: none;
      background: #106cb6;

      &.not-active {
        opacity: 0.4;
      }
      &.not-active span {
        opacity: 1;
      }
    }

    &:hover {
      & div {
        opacity: 0.9;
      }
    }
  }
`;

const ActionInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const ActionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border: 1px dashed #ffffff;
  border-radius: 360px;

  @media (max-width: 1200px) {
    width: 100px;
    height: 100px;
  }

  svg {
    width: 55px;
    height: 55px;
    path {
      stroke: #b0dbff;
    }
  }
`;

const ActionTitle = styled.div`
  max-width: 360px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
`;

const ActionText = styled.div<{ isError?: boolean }>`
  color: #959595;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;

  ${({ isError }) =>
    isError &&
    `
      color: #ff6c76;
    `}
`;

const Info = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 9px;
  font-size: 15px;
  font-weight: 800;
  line-height: 18.83px;
  color: #ff9000;

  :hover > div {
    left: -70px;
    top: 30px;
    padding: 12px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    width: 280px;
    box-shadow: none;
    opacity: 0.9;
    visibility: visible;
    background: #292937;

    &::after {
      opacity: 0.6;
      border-color: transparent transparent #292937 transparent;
    }

    span {
      font-weight: 500;
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }

  > svg path {
    stroke: #ff9000;
  }
`;

export default MediaCard;
