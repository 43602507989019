export const UPDATE_POPUP = "UPDATE_POPUP";

export enum Popups {
  createNewProjectPopup = "createNewProjectPopup",
  estimatedPopup = "estimatedPopup",
  characterPopup = "characterPopup",
  aIHumansPopup = "aIHumansPopup",
  templatesPopup = "templatesPopup",
  generativeVideo = "generativeVideo",
  shareVideoLinkPopup = "shareVideoLinkPopup",
  confirmationPopup = "confirmationPopup",
  chatPopup = "chatPopup",
  vimeoPopup = "vimeoPopup",
  historyPopup = "historyPopup",
  myLibraryPopup = "myLibraryPopup",
  addVoiceAudioPopup = "addVoiceAudioPopup",
  addVoiceAudioInfoPopup = "addVoiceAudioInfoPopup",
  aIHumansPreviewPopup = "AIHumansPreviewPopup",
  signUpPopup = "signUpPopup",
  enlargedImagePopup = "enlargedImagePopup",
  text2VideoPopup = "text2VideoPopup",
  uploadAIImageAssetPopup = "uploadAIImageAssetPopup",
  trimImagePopup = "trimImagePopup",
  aIImageRemoveAreaPopup = "aIImageRemoveAreaPopup",
  cropImagePopup = "cropImagePopup",
  notEnoughCreditsPopup = "notEnoughCreditsPopup",
  cancelSubscriptionPopup = "cancelSubscriptionPopup",
  subscriptionCanceledPopup = "subscriptionCanceledPopup",
  changeSubscriptionPopup = "changeSubscriptionPopup",
  paymentMethodPKSPopup = "paymentMethodPKSPopup",
  changedSubscriptionPopup = "changedSubscriptionPopup",
  deleteAccountPopup = "deleteAccountPopup",
  addCustomAvatarPopup = "addCustomAvatarPopupIsOpen",
  selectTypeCustomAvatarPopup = "selectTypeCustomAvatarPopup",
  embedOnWebsitePopup = "embedOnWebsitePopup",
  aiVoicesText2SpeechReplaceProjectPopup = "aiVoicesText2SpeechReplaceProjectPopup",
  faceswapReplaceProjectPopup = "faceswapReplaceProjectPopup",
}

interface UpdatePopupProps {
  popup: Popups;
  status: boolean;
  prefilled?: any;
}

export const updatePopup = ({ popup, status, prefilled }: UpdatePopupProps) => ({
  type: UPDATE_POPUP,
  payload: {
    popup,
    status,
    prefilled,
  },
});
