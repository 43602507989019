import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../Modal/Modal";
import CloseIconV4 from "../Icons/CloseIconV4";
import CircularProgress from "../Icons/CircularProgress";
import Button, { ButtonThemes } from "../Button/Button";
import { FolderIcon } from "../Icons/FolderIcon";
import { FaceswapProjectTab } from "../../modules/FaceSwap";
import { UserHistoryProject } from "../../types/userHistoryProjects";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { getMyLibraryPopupIsOpen, getPrefilled } from "../../redux/reducers/popupsReducer";
import { clearUserHistoryProjects, getUserHistoryProjectsInfiniteServer } from "../../redux/actions/profileActions";
import {
  getCurrentPageUserHistoryProjects,
  getHasMoreUserHistoryProjects,
  getUserHistoryProjects,
  getUserHistoryProjectsLoading,
} from "../../redux/reducers/profileReducer";
import Select from "../Select/Select";
import { BlueArrowDown } from "../Icons/Icons";
import Tabs from "../../modules/FaceSwap/components/Tabs";

interface SelectedItem {
  id?: number;
  output?: string;
}

const sortByDateOptions = {
  data: [
    {
      id: "1",
      label: "Newest to Oldest",
      value: true,
    },
    {
      id: "2",
      label: "Oldest to Newest",
      value: false,
    },
  ],
};

const MyLibraryPopup = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getUserHistoryProjectsLoading);
  const isMyLibraryPopupVisible = useSelector(getMyLibraryPopupIsOpen);
  const { type, mediaType, onClick } = useSelector(getPrefilled);
  const [selectedMediaUrl, setSelectedMediaUrl] = useState<SelectedItem>({});
  const projects = useSelector(getUserHistoryProjects);
  const currentPage = useSelector(getCurrentPageUserHistoryProjects);
  const hasMore = useSelector(getHasMoreUserHistoryProjects);

  const [sortByDesc, setSortByDesc] = useState(true);
  const [activeTab, setActiveTab] = useState(mediaType);

  const getProjectList = (currenpPage: number) => {
    dispatch(
      getUserHistoryProjectsInfiniteServer({
        category: "",
        pageNumber: currenpPage + 1,
        pageSize: 24,
        mediaType: activeTab,
        sortByDesc,
      }),
    );
  };
  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.myLibraryPopup, status: false }));
  };

  const handleProjectClick = (item: UserHistoryProject) => {
    if (!(type in FaceswapProjectTab)) return;
    setSelectedMediaUrl({
      id: item.id,
      output: mediaType === "Video" ? item.output : item.coverImage,
    });
  };

  const handleSelectButton = () => {
    onClick(selectedMediaUrl.output);
    handleClose();
  };

  const onSortByDescChanges = (value: boolean) => {
    setSortByDesc(value);
  };

  const handleActiveTabChanges = (value: string) => {
    setActiveTab(value);
  };

  useEffect(() => {
    dispatch(clearUserHistoryProjects());
    let request: { category: string; pageNumber: number; pageSize: number; mediaType?: string; sortByDesc?: boolean } =
      {
        category: "",
        pageNumber: 1,
        pageSize: 20,
        sortByDesc,
        mediaType: activeTab,
      };
    dispatch(getUserHistoryProjectsInfiniteServer(request));
  }, [sortByDesc, activeTab]);

  return (
    <StyledModal
      className="library"
      childrenClassName="childrenLibrary"
      open={isMyLibraryPopupVisible}
      onClose={handleClose}
      closeIcon={true}
      closeIconElement={<CloseIconV4 />}
      maxWidth={570}
      maxHeight={512}
      title={
        <ModalTitle>
          <FolderIcon />
          My Library
        </ModalTitle>
      }
    >
      <FilterWrapper>
        <SelectWrapper isValueExist>
          <Select
            value={sortByDesc}
            onChange={onSortByDescChanges}
            optionsList={sortByDateOptions}
            defaultValueText="Sort By"
            actionIcon={<BlueArrowDown />}
          />
        </SelectWrapper>
        <TabsWrapper>
          <Tabs
            data={[{ label: "Image", disabled: mediaType === "Video" }, { label: "Video" }]}
            active={activeTab}
            handleActive={handleActiveTabChanges}
          />
        </TabsWrapper>
      </FilterWrapper>
      <Content isLoading={isLoading} selected={!!selectedMediaUrl.output}>
        {isLoading && !projects.length ? (
          <CircularProgress color="#009af7" />
        ) : (
          <>
            <Projects id="scrollableDiv">
              <InfiniteScroll
                next={() => getProjectList(currentPage)}
                hasMore={hasMore}
                loader={isLoading ? <CircularProgress color="#009af7" /> : null}
                dataLength={projects?.length}
                style={{ display: "contents" }}
                scrollableTarget="scrollableDiv"
              >
                {projects.map((item) => (
                  <ProjectCard key={item.id} onClick={() => handleProjectClick(item)}>
                    <ProjectMedia selected={selectedMediaUrl.id === item.id}>
                      <img src={item.coverImage} />
                    </ProjectMedia>
                    <ProjectTitle>{item.title}</ProjectTitle>
                  </ProjectCard>
                ))}
              </InfiniteScroll>
            </Projects>
            <Control>
              <Button
                buttonTheme={ButtonThemes.Primary}
                disabled={!selectedMediaUrl.output}
                onClick={handleSelectButton}
              >
                Select
              </Button>
            </Control>
          </>
        )}
      </Content>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  &.library {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 18px 30px 25px;
    border-radius: 20px;

    > span {
      height: 24px;
    }
  }

  & div.modal-close-icon {
    width: 21px;
    height: 14px;
    top: 21px;
    right: 14px;

    svg {
      width: 21px;
      height: 14px;
    }
  }

  & .childrenLibrary {
    height: calc(100% - 24px);
  }

  @media (max-height: 563px) {
    max-height: 95%;
  }
`;

const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  gap: 10px;

  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
`;

const Content = styled.div<{ isLoading?: boolean; selected?: boolean }>`
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${({ isLoading }) =>
    isLoading &&
    `
    align-items: center;
    justify-content: center;
  `}

  button {
    align-self: center;
    width: 112px;
    height: 38px;
    cursor: not-allowed;
    box-shadow: unset;

    background: ${({ theme }) => theme.activeMenu};
    color: ${({ theme }) => theme.primaryText};

    ${({ selected }) =>
      selected &&
      `
    cursor: pointer;
  `}
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

const SelectWrapper = styled.div<{ isValueExist: boolean }>`
  width: 180px;

  .selector-container {
    div {
      box-shadow: none;
    }

    .selector-labels {
      display: ${({ isValueExist }) => (isValueExist ? "none" : "flex")};
    }

    align-items: center;
    .select-option-list {
      background-color: ${({ theme }) => theme.secondaryText};
    }
    span {
      padding: 3px;
      border: none;
      color: ${({ theme }) => theme.primaryText};
    }
    svg {
      background: none !important;
      top: 16px;
    }
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  height: 31px;
  border-radius: 52px;
  border: 0px;
  background: #292937;

  & > div {
    background: #292937;
    box-shadow: none;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 52px;
      border: 0px;

      & > div {
        top: 40px;
        left: 140px;
        width: 290px;
        opacity: 0.6;
        border-radius: 7px;
        background: #292937;

        &::after {
          opacity: 0.6;
          border-color: transparent transparent #292937 transparent;
        }
      }

      &:hover {
        & div {
          opacity: 0.9;
        }
      }
    }

    button {
      box-shadow: none;
      background: #106cb6;
      cursor: pointer;

      &.not-active {
        opacity: 0.4;
      }
      &.not-active span {
        opacity: 1;
      }
    }

    button:disabled {
      cursor: default;
    }
  }

  @media (max-width: 1000px) {
    margin: 48px auto 18px 0;
  }
`;

const Projects = styled.div`
  display: flex;
  align-content: stretch;
  overflow: hidden auto;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  max-height: 100%;
  margin-top: 14px;
  gap: 10px;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
    border: 5px solid transparent;
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-track {
    margin: 4px 0;
  }
`;

const ProjectCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 112px;
  cursor: pointer;
  gap: 6px;
  border-radius: 8px;
  padding: 4px;
  background: ${({ theme }) => theme.inputText};
`;

const ProjectMedia = styled.div<{ selected?: boolean }>`
  width: 112px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 4px solid transparent;

    ${({ selected }) =>
      selected &&
      `
    border: 4px solid #0063B4;
  `}
  }
`;

const ProjectTitle = styled.span`
  display: block;
  display: -webkit-box;
  color: ${({ theme }) => theme.lightGreyText};
  font-family: Mulish;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
`;

const Control = styled.div`
  display: flex;
  justify-content: center;
  height: 38px;
  margin-top: auto;
`;

export default MyLibraryPopup;
