import { put, select, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { AuthModules } from "../../types/auth";

import {
  ACTIVE_USER_BY_APPSUMO_TOKEN_SERVER,
  GET_TOKEN_USER,
  GET_USER_BY_APPSUMO_TOKEN_SERVER,
  LOGOUT,
  Oauth,
  RECOVER_PASSWORD_SERVER,
  REGISTER_GUEST_USER_SERVER,
  RESET_PASSWORD_SERVER,
  setIsAuthorized,
  SIGN_IN_SERVER,
  SIGN_IN_WITH_GOOGLE_SERVER,
  SIGN_UP_SERVER,
  updateAuthLoading,
} from "../actions/authActions";
import Navigate from "../../lib/Navigate";
import { pages } from "../../lib/routeUtils";
import { updateProfile } from "../actions/profileActions";
import { getRedirectToProject } from "../reducers/projectReducer";
import { redirectToProject } from "../actions/projectAction";
import { createSearchParams } from "react-router-dom";
import { getOauth } from "../reducers/authReducer";
import { getProfile } from "../reducers/profileReducer";

const authSagas = [
  takeEvery(LOGOUT, logoutHandler),

  takeEvery(`${SIGN_IN_SERVER}`, handleSignIn),
  takeEvery(`${SIGN_IN_SERVER}_FAIL`, handleSignInFail),
  takeEvery(`${SIGN_IN_SERVER}_SUCCESS`, handleSignInSuccess),

  takeEvery(`${SIGN_UP_SERVER}`, handleSignUp),
  takeEvery(`${SIGN_UP_SERVER}_FAIL`, handleSignUpFail),
  takeEvery(`${SIGN_UP_SERVER}_SUCCESS`, handleSignUpSuccess),

  takeEvery(`${RECOVER_PASSWORD_SERVER}`, handleRecoverPassword),
  takeEvery(`${RECOVER_PASSWORD_SERVER}_FAIL`, handleRecoverPasswordFail),
  takeEvery(`${RECOVER_PASSWORD_SERVER}_SUCCESS`, handleRecoverPasswordSuccess),

  takeEvery(`${RESET_PASSWORD_SERVER}`, handleResetPassword),
  takeEvery(`${RESET_PASSWORD_SERVER}_FAIL`, handleResetPasswordFail),
  takeEvery(`${RESET_PASSWORD_SERVER}_SUCCESS`, handleResetPasswordSuccess),

  takeEvery(`${GET_TOKEN_USER}`, handleGetTokenUser),
  takeEvery(`${GET_TOKEN_USER}_FAIL`, handleGetTokenUserFail),
  takeEvery(`${GET_TOKEN_USER}_SUCCESS`, handleGetTokenUserSuccess),

  takeEvery(`${REGISTER_GUEST_USER_SERVER}`, handleRegisterGuestUserServer),
  takeEvery(`${REGISTER_GUEST_USER_SERVER}_FAIL`, handleRegisterGuestUserServerFail),
  takeEvery(`${REGISTER_GUEST_USER_SERVER}_SUCCESS`, handleRegisterGuestUserServerSuccess),

  takeEvery(`${ACTIVE_USER_BY_APPSUMO_TOKEN_SERVER}_SUCCESS`, handleLoginWithAppSumoServerSuccess),
  takeEvery(`${GET_USER_BY_APPSUMO_TOKEN_SERVER}_FAIL`, handleGetAccountBySumoTokenServerFail),

  takeEvery(SIGN_IN_WITH_GOOGLE_SERVER, handleSignInWithGoogleServer),
  takeEvery(`${SIGN_IN_WITH_GOOGLE_SERVER}_SUCCESS`, handleSignInWithGoogleServerSuccess),
  takeEvery(`${SIGN_IN_WITH_GOOGLE_SERVER}_FAIL`, handleSignInWithGoogleServerFail),
];

function* logoutHandler() {
  yield Navigate.push(pages.signIn());
  yield put(updateProfile({}));
  window.location.reload();
}

function* handleSignIn() {
  yield put(updateAuthLoading({ module: AuthModules.signIn, isLoading: true }));
}

function* handleSignInFail(action: any) {
  yield toast.error(
    action?.error?.response?.data?.message || "Sign in failed, check your credentials or try again later",
  );
  yield put(updateAuthLoading({ module: AuthModules.signIn, isLoading: false }));
}

function* handleSignInSuccess(action: any) {
  const redirectTo = getRedirectToProject(yield select());
  const { redirect_uri, state } = getOauth(yield select());
  yield put(setIsAuthorized(true));
  yield put(updateAuthLoading({ module: AuthModules.signIn, isLoading: false }));

  const userToken = action?.payload?.data?.data?.user?.userToken;
  if (redirect_uri && state) {
    window.location.href = `${redirect_uri}?state=${state}&code=${userToken}`;
    // yield Oauth({ redirect_uri: "", state: "" });
    return;
  }

  if (redirectTo) {
    yield put(redirectToProject({ projectName: "", projectId: 0 }));
    yield Navigate.push(redirectTo);
  } else {
    yield Navigate.push(pages.main());
  }
}

function* handleSignUp() {
  yield put(updateAuthLoading({ module: AuthModules.signUp, isLoading: true }));
}

function* handleSignUpFail(action: any) {
  yield toast.error(action?.error?.response?.data?.message || "Sign up failed");
  yield put(updateAuthLoading({ module: AuthModules.signUp, isLoading: false }));
}

function* handleSignUpSuccess(action: any) {
  const { token } = action.meta.previousAction.payload.request.data;

  if (token === "Trial") {
    yield toast.success("Activation Details sent to your email");
  } else {
    yield put(setIsAuthorized(true));
    yield Navigate.push(pages.main("mail"));
  }

  yield put(updateAuthLoading({ module: AuthModules.signUp, isLoading: false }));
}

function* handleRecoverPassword() {
  yield put(updateAuthLoading({ module: AuthModules.recoverPassword, isLoading: true }));
  yield put(updateAuthLoading({ module: AuthModules.checkMail, isLoading: false }));
}

function* handleRecoverPasswordFail(action: any) {
  yield toast.error(action?.error?.response?.data?.message || "Recover Password failed");
  yield put(updateAuthLoading({ module: AuthModules.recoverPassword, isLoading: false }));
}

function* handleRecoverPasswordSuccess(action: any) {
  const { message, succeeded } = action.payload.data;

  if (succeeded) {
    // yield toast.success(message);
    // yield Navigate.push(pages.signIn());
    yield put(updateAuthLoading({ module: AuthModules.checkMail, isLoading: true }));
  }

  yield put(updateAuthLoading({ module: AuthModules.recoverPassword, isLoading: false }));
}

function* handleResetPassword() {
  yield put(updateAuthLoading({ module: AuthModules.resetPassword, isLoading: true }));
}

function* handleResetPasswordFail() {
  yield toast.error("Reset Password failed");
  yield put(updateAuthLoading({ module: AuthModules.resetPassword, isLoading: false }));
}

function* handleResetPasswordSuccess(action: any) {
  const { message, succeeded } = action.payload.data;

  if (succeeded) {
    // yield toast.success(message);
    yield Navigate.push(pages.signIn());
  }

  yield put(updateAuthLoading({ module: AuthModules.resetPassword, isLoading: false }));
}

function* handleGetTokenUser() {
  yield put(updateAuthLoading({ module: AuthModules.getTokenUser, isLoading: true }));
}

function* handleGetTokenUserFail() {
  yield toast.error("Wrong token");
  yield put(updateAuthLoading({ module: AuthModules.getTokenUser, isLoading: false }));
}

function* handleGetTokenUserSuccess(action: any) {
  yield put(updateAuthLoading({ module: AuthModules.getTokenUser, isLoading: false }));
}

function* handleRegisterGuestUserServer() {
  yield put(updateAuthLoading({ module: AuthModules.registerGuestUser, isLoading: true }));
}

function* handleRegisterGuestUserServerFail(action: any) {
  yield toast.error(action?.error?.response?.data?.message || "Guest register failed");
  yield put(updateAuthLoading({ module: AuthModules.registerGuestUser, isLoading: false }));
}

function* handleRegisterGuestUserServerSuccess(action: any) {
  const { page, otherParams } = action.meta.previousAction.payload;

  yield toast.success("Free trial has started!");
  yield put(setIsAuthorized(true));
  yield put(updateAuthLoading({ module: AuthModules.registerGuestUser, isLoading: false }));
  const otherParamsParsed = otherParams.reduce((acc: Record<string, any>, item: Record<string, any>) => {
    return { ...acc, ...item };
  }, {});

  if (page) {
    yield Navigate.push(page + "?" + createSearchParams(otherParamsParsed).toString());
  } else {
    yield Navigate.push(pages.main() + "?" + createSearchParams(otherParamsParsed).toString());
  }
}

function* handleGetAccountBySumoTokenServerFail() {
  yield toast.error("Failed to get your user from AppSumo, please contact Synthesys support");
  yield Navigate.push(pages.signIn());
}

function* handleLoginWithAppSumoServerSuccess() {
  yield toast.success("Account created with AppSumo!");
  yield put(setIsAuthorized(true));
  yield Navigate.push(pages.main());
}

function* handleSignInWithGoogleServer() {
  yield put(updateAuthLoading({ module: AuthModules.signInWithGoogle, isLoading: true }));
}

function* handleSignInWithGoogleServerFail(action: any) {
  yield toast.error(action?.error?.response?.data?.message || "Sign in with Google failed");
  yield put(updateAuthLoading({ module: AuthModules.signInWithGoogle, isLoading: false }));
}

function* handleSignInWithGoogleServerSuccess(action: any) {
  const { isSignUp } = action.meta.previousAction.payload;
  const { redirect_uri, state } = getOauth(yield select());

  yield put(setIsAuthorized(true));
  yield put(updateAuthLoading({ module: AuthModules.signInWithGoogle, isLoading: false }));

  const userToken = action?.payload?.data?.data?.user?.userToken;
  console.log("actionnnnnnn", action);
  console.log("actionnnnnnn userToken", userToken);
  if (!isSignUp && redirect_uri && state) {
    window.location.href = `${redirect_uri}?state=${state}&code=${userToken}`;
    // yield Oauth({ redirect_uri: "", state: "" });
    return;
  }

  if (action?.payload?.data?.data?.new_user) {
    yield Navigate.push(pages.main("google"));
  } else {
    yield Navigate.push(pages.main());
  }
}

export default authSagas;
