export const ShuffleIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="shuffle">
      <path
        id="Oval 4"
        d="M21 16.0399H17.7707C15.8164 16.0399 13.9845 14.9697 12.8611 13.1716L10.7973 9.86831C9.67384 8.07022 7.84196 7 5.88762 7L3 7"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        id="Oval 4.1"
        d="M21 7H17.7707C15.8164 7 13.9845 8.18388 12.8611 10.1729L10.7973 13.8271C9.67384 15.8161 7.84196 17 5.88762 17L3 17"
        stroke="white"
        strokeLinecap="round"
      />
      <path id="Path 3" d="M19 4L22 7L19 10" stroke="white" strokeLinecap="round" />
      <path id="Path 3_2" d="M19 13L22 16L19 19" stroke="white" strokeLinecap="round" />
    </g>
  </svg>
);
