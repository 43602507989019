export enum FaceSwapType {
  image = "image",
  video = "video",
}

export enum FaceswapModules {
  "target" = "target",
  "source" = "source",
  "faceswap" = "faceswap",
  "faceswapList" = "faceswapList",
  "pageLoading" = "pageLoading",
  "audioFromText" = "audioFromText",
  "imageFromText" = "imageFromText",
  "percent" = "percent",
}

export interface FaceswapProject {
  faceswapProjectId: number;
  userId: number;
  title: string;
  faceswapType: string;
  source: string;
  target: string;
  insertDateTime: string;
  updateDateTime: string;
  isDeleted: boolean;
  estimatedTime: number;
  status: number;
  outputUrl: string;
  totalSecondsTaken: number;
  completeDateTime: string;
  estimatedCompleteDateTime: string;
  progress: string;
  guid: string;
  input: Input;
  coverImage: string;
  serviceTypeID: number;
}

export interface AIServiceProject {
  aiServiceID: number;
  userID: number;
  serviceTypeID: number;
  title: string;
  estimatedTime: number;
  totalSecondsTaken: number;
  completeDateTime: string;
  estimatedCompleteDateTime: string;
  progress: string;
  input: Input;
  output: string;
  insertDateTime: string;
  updateDateTime?: any;
  isDeleted: boolean;
  status: number;
  guid: string;
  coverImage: string;
}

export interface Input {
  image: string;
  video?: string;
  audio?: string;
  prompt?: string;
}
