import styled from "styled-components";

interface ProgressBarProps {
  percent: number | string;
}

const ProgressBar = ({ percent }: ProgressBarProps) => (
  <ProgressBar.Wrapper>
    <ProgressBar.Line>
      <ProgressBar.FillLine percent={percent} />
    </ProgressBar.Line>
    <ProgressBar.Label>{percent}%</ProgressBar.Label>
  </ProgressBar.Wrapper>
);

ProgressBar.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

ProgressBar.Line = styled.div`
  width: 100%;
  max-width: 355px;
  height: 16px;
  border-radius: 4px;
  overflow: hidden;
  background-color: #3b3d47;
`;

ProgressBar.FillLine = styled.div<{ percent?: string | number }>`
  width: ${({ percent }) => percent}%;
  height: 100%;
  border-radius: 4px;
  background-color: #0063b4;
  transition: width 1s ease-in-out;
`;

ProgressBar.Label = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #ffffffa3;
`;

export default ProgressBar;
