import styled from "styled-components";
import { toast } from "react-toastify";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tabs from "./Tabs";
import VoiceSelect from "./VoiceSelect";
import AudioPlayerWidget from "./AudioPlayerWidget";
import Tooltip from "../../../components/Tooltip/Tooltip";
import TextArea from "../../../components/TextArea/TextArea";
import CircularProgress from "../../../components/Icons/CircularProgress";
import Button, { ButtonThemes } from "../../../components/Button/Button";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import {
  clearImageByType,
  faceswapUploadFileServer,
  generateAudioServer,
  setTextForAudio,
  setVoiceSelect,
} from "../../../redux/actions/faceswapActions";
import {
  getActorIdForAudio,
  getFaceswap,
  getFaceswapTarget,
  getFaceswapTargetLoading,
  getTextForAudio,
} from "../../../redux/reducers/faceswapReducer";
import { PlayIcon } from "../../../components/Icons/PlayIcon";
import { getProfile } from "../../../redux/reducers/profileReducer";
import { DropdownIconThin } from "../../../components/Icons/DropdownIconThin";
import { getActorsList, getActorsListLoading } from "../../../redux/reducers/actorReducer";
import { InfoIcon, LibraryIcon, SoundWaveIcon, UploadBoxIcon } from "../../../components/Icons/FaceSwapIcons";

const TEXT_LIMITATION = 300;
const TEXT_LIMITATION_FOR_UNLIMITED = 500;
const MAX_SIZE_AUDIO = 52428800;
const ACCEPTED_FORMATS_AUDIO = "audio/mp3, audio/wav, audio/mpeg";

enum TabsEnum {
  "Generate Audio" = "Generate Audio",
  "Import Audio" = "Import Audio",
}

const tabs = [
  {
    label: TabsEnum["Generate Audio"],
    endAdornment: <InfoIcon />,
    tooltip: "Create a voiceover with a text script and one of our best in class voice actors.",
  },
  {
    label: TabsEnum["Import Audio"],
    endAdornment: <InfoIcon />,
    tooltip: "Import an existing audio voiceover file to use for the lip-sync.",
  },
];

const AudioCard = () => {
  const dispatch = useDispatch();
  const project = useSelector(getFaceswap);
  const actorsServer = useSelector(getActorsList);
  const targetUrl = useSelector(getFaceswapTarget);
  const textFieldValue = useSelector(getTextForAudio);
  const selectVoice = useSelector(getActorIdForAudio);

  const isLoading = useSelector(getFaceswapTargetLoading);
  const actorsLoading = useSelector(getActorsListLoading);
  const { hasUnlimitedFaceswap } = useSelector(getProfile);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [activeTab, setActiveTab] = useState<TabsEnum>(tabs[0].label);
  const [errorSize, setErrorSize] = useState<boolean>(false);

  const target = project?.target || targetUrl;
  const isAction = !project || !project?.faceswapProjectId;
  const actors = actorsServer.length
    ? actorsServer.map((actor) => ({
        actorid: actor.actorId,
        Name: actor.name,
        src: actor.audioSampleLink,
      }))
    : [];

  const handleVoiceSelectChanges = (newValue: any) => dispatch(setVoiceSelect(newValue));

  const handleActiveMainTab = (tab: string) => setActiveTab(tab as TabsEnum);

  const handleInputClick = () => {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  const handleTextAreaChange = (e: any) => {
    const value = e.target.value;
    if (value.length <= (hasUnlimitedFaceswap ? TEXT_LIMITATION_FOR_UNLIMITED : TEXT_LIMITATION)) {
      dispatch(setTextForAudio(value));
    } else {
      toast.error(
        `Please make sure your project doesn’t exceed the maximum amount of ${
          hasUnlimitedFaceswap ? TEXT_LIMITATION_FOR_UNLIMITED : TEXT_LIMITATION
        } characters, this is done to achieve high quality and rendering speed of the output`,
      );
    }
  };

  const handleDelete = () => dispatch(clearImageByType("target"));

  const handleUpload = ({ target }: any) => {
    const uploadedFile = target.files[0];

    if (!uploadedFile) return;

    const allowedTypes = /audio\/(mp3|wav|mpeg)/;

    if (uploadedFile && uploadedFile.size > MAX_SIZE_AUDIO) {
      if (inputRef.current) {
        inputRef.current.value = "";
      }

      setErrorSize(true);
      toast.error(`The file must not exceed 50MB! Please upload a smaller file.`);
    } else if (!uploadedFile.type.match(allowedTypes)) {
      if (inputRef.current) {
        inputRef.current.value = "";
      }

      setErrorSize(true);
      toast.error(`Incorrect file format! Please upload the file in MP3 or WAV format.`);
    } else {
      const formData = new FormData();
      formData.append("File", uploadedFile);

      setErrorSize(false);
      dispatch(faceswapUploadFileServer({ data: formData, type: "target" }));
    }
  };

  const generateAudio = () => {
    const generateAudio = {
      text: textFieldValue,
      features: [],
      actorId: parseInt(selectVoice.toString()),
    };

    dispatch(generateAudioServer({ data: [generateAudio] }));
  };

  const content = {
    [TabsEnum["Generate Audio"]]: (
      <>
        <TextFieldWrapper>
          <TextArea
            value={textFieldValue}
            placeholder="You can type here"
            onChange={handleTextAreaChange}
            disabled={isLoading}
            rows={9}
          />
          <Footer
            isFull={textFieldValue.length >= (hasUnlimitedFaceswap ? TEXT_LIMITATION_FOR_UNLIMITED : TEXT_LIMITATION)}
          >
            <span>
              {textFieldValue.length}/{hasUnlimitedFaceswap ? TEXT_LIMITATION_FOR_UNLIMITED : TEXT_LIMITATION}
            </span>
          </Footer>
        </TextFieldWrapper>
        <ActionsWrapper>
          <SelectWrapper>
            {!actorsLoading ? (
              <VoiceSelect
                optionsList={actors}
                value={selectVoice}
                placeholder="Select a voice"
                onChange={handleVoiceSelectChanges}
                actionIcon={<DropdownIconThin />}
                disabled={isLoading}
              />
            ) : (
              <LoadingWrapper>
                <CircularProgress color="#fff" />
              </LoadingWrapper>
            )}
          </SelectWrapper>
          <PlayButton>
            <IconButton
              onClick={generateAudio}
              className="rounded"
              iconButtonTheme={IconButtonThemes.Rounded}
              icon={isLoading ? <CircularProgress color="#fff" /> : <PlayIcon />}
              disabled={!selectVoice || !textFieldValue || isLoading}
            />
            <Tooltip text="Generate audio" arrow />
          </PlayButton>
        </ActionsWrapper>
      </>
    ),
    [TabsEnum["Import Audio"]]: (
      <>
        <ActionInfo>
          <ActionIcon>
            <LibraryIcon />
          </ActionIcon>
          <ActionTitle>Drag and drop an audio here or click to upload</ActionTitle>
          <ActionText isError={errorSize}>File Supported: MP3, WAV. Maximum size: 50MB, up to 3 minutes</ActionText>
        </ActionInfo>
      </>
    ),
  };

  return (
    <Card>
      <Title>
        <SoundWaveIcon />
        <Text>Audio</Text>
        <TabsWrapper>
          <Tabs data={tabs} active={activeTab} handleActive={handleActiveMainTab} disabled={isLoading || !!target} />
        </TabsWrapper>
      </Title>
      <DragAndDropWrapper visible={!!target}>
        <CardContent>
          {activeTab === TabsEnum["Import Audio"] && (
            <>
              {!isLoading && isAction && (
                <input
                  ref={inputRef}
                  type="file"
                  id="faceswapTarget"
                  name="faceswapTarget"
                  accept={ACCEPTED_FORMATS_AUDIO}
                  onChange={handleUpload}
                />
              )}
            </>
          )}
          {/* {target ? (
            <>
              <audio src={target} ref={audioPlayer} onTimeUpdate={onPlaying} onLoadedMetadata={onLoadedMetadata} />
              <AudioWrapper>
                <IconButton
                  onClick={toggleAudioPlay}
                  iconButtonTheme={IconButtonThemes.Rounded}
                  icon={!playing || currentTime === duration ? <PlayIcon /> : <PauseIcon />}
                />
              </AudioWrapper>
            </>
          ) : (
            content[activeTab]
          )} */}
          {content[activeTab]}
        </CardContent>
        {isAction && (
          <ButtonsWrapper>
            {activeTab === TabsEnum["Import Audio"] && (
              <Button
                text={isLoading ? "Loading..." : "Upload"}
                icon={<UploadBoxIcon />}
                disabled={isLoading}
                buttonTheme={ButtonThemes.Transparent}
                onClick={handleInputClick}
                style={{ zIndex: "1" }}
              />
            )}
            {target && (
              <Button
                text={"Delete"}
                onClick={handleDelete}
                buttonTheme={ButtonThemes.Transparent}
                style={{ zIndex: "1" }}
              />
            )}
          </ButtonsWrapper>
        )}
      </DragAndDropWrapper>
      {target && <AudioPlayerWidget url={target} />}
    </Card>
  );
};
const Card = styled.div`
  position: relative;
  flex: 3;
  background: #15171a;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  padding: 17px 18px;
  margin-bottom: 18px;
  min-width: 578px;

  @media (max-width: 1200px) {
    min-width: auto;
    padding: 10px 18px 17px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  gap: 10px;

  & > svg {
    width: 25px;
    height: 25px;
  }

  & > :first-child {
    width: 32px;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  margin-right: auto;
`;

const AudioWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;

  button {
    width: 200px;
    height: 200px;
    max-width: 200px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    svg {
      width: 80px;
      height: 80px;
    }
  }
`;

const DragAndDropWrapper = styled.div<{ visible?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & > div > input {
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    cursor: pointer;
    ${({ visible }) =>
      visible &&
      `
        z-index: -1;
      `}
  }
`;

const Footer = styled.div<{ isFull?: boolean }>`
  padding: 0 16px 24px 0;
  display: flex;
  width: 90px;
  justify-content: end;
  margin-top: auto;
  position: absolute;
  z-index: 5;
  right: 0;
  bottom: -10px;
  text-align: center;

  span {
    font-family: "Mulish", sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${({ isFull, theme }) => (isFull ? theme.pink : `${theme.primaryText}66`)};
  }
`;

const TextFieldWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;

  textarea {
    border-radius: 32px;
    padding: 19px 25px;
    background: #1f2024;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  gap: 26px;
  margin: 26px 0;
  width: 100%;
`;

const SelectWrapper = styled.div`
  width: 100%;
  height: 44px;
`;

const PlayButton = styled.div`
  position: relative;

  & button {
    width: 32px;
    height: 32px;
    background: #0063b4;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &:hover > div {
    top: -42px;
    right: 5px;
    opacity: 1;
    visibility: visible;
    display: flex;
    align-items: center;
    border-radius: 7px;
    min-width: max-content;
    box-shadow: none;
    background: #292937;
    padding: 5px;

    &:after {
      border-color: #292937 transparent transparent transparent;
      right: 5px;
      bottom: -13px;
    }
  }
`;

const CardContent = styled.div`
  width: 100%;
  height: 100%;
  min-height: 232px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 25px;

  & button {
    height: 30px;
    max-width: 130px;
    padding: 0px 18px;
    gap: 10px;
    border-radius: 50px;
    border: 1px solid #6f7074;
    justify-content: center;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  margin-right: 0px;
  height: 32px;
  border-radius: 52px;
  border: 0px;
  background: #292937;

  & > div {
    background: #292937;
    box-shadow: none;

    & > div {
      width: 165px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 52px;
      border: 0px;

      & > div {
        top: 40px;
        left: -40px;
        width: 280px;
        opacity: 0.6;
        border-radius: 7px;
        background: #292937;

        &::after {
          opacity: 0.6;
          border-color: transparent transparent #292937 transparent;
        }
      }

      &:hover {
        & div {
          opacity: 0.9;
        }
      }
    }

    button {
      box-shadow: none;
      background: #106cb6;

      &.not-active {
        opacity: 0.4;
      }
      &.not-active span {
        opacity: 1;
      }
    }
  }
`;

const ActionInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const ActionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border: 1px dashed #ffffff;
  border-radius: 360px;
  padding: auto;

  @media (max-width: 1200px) {
    width: 100px;
    height: 100px;
  }

  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: #b0dbff;
    }
  }
`;

const ActionTitle = styled.div`
  max-width: 350px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
`;

const ActionText = styled.div<{ isError?: boolean }>`
  color: #959595;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;

  ${({ isError }) =>
    isError &&
    `
      color: #ff6c76;
    `}
`;

const LoadingWrapper = styled("div")`
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid #23406b;
  background-color: #15171a;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    display: block;
  }
`;

export default AudioCard;
