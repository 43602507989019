export const formatDateString = (dateString: string | undefined) => {
  if (!dateString) return null;

  const hasTimeZone = /[+-]\d{2}:\d{2}$/.test(dateString);

  if (!hasTimeZone && !dateString.endsWith("Z")) {
    return new Date(dateString + "Z");
  }

  return new Date(dateString);
};
