import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";

import Visualize from "./components/Visualise";
import ChatPopup from "../Chat/components/ChatPopup";
import ChatTrigger from "../Chat/components/ChatTrigger";
import AIImagesContent from "./components/AIImagesContent";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import DashboardLayout from "../../layouts/DashboardLayout";
import AIImagesLeftSidebar from "./components/AIImagesLeftSidebar";
import AIImagesRightSidebar from "./components/AIImagesRightSidebar";
import Button, { ButtonThemes } from "../../components/Button/Button";
import { AIImagesContext } from "../../App";
import { AIImagesSidebarTabs } from "../../hooks/useAiImages";
import { getInPaintImage } from "../../redux/reducers/imagesReducer";

const AIImagesPage = () => {
  const location = useLocation();
  // const uploadImage = useSelector(getUploadedImage);
  const inPaintImage = useSelector(getInPaintImage);
  const searchParams = new URLSearchParams(location.search);
  const { setActiveTab, setUploadedImage } = useContext(AIImagesContext);
  const [isOpenVisualizePopup, setIsOpenVisualizePopup] = useState(false);

  // useEffect(() => {
  //   if (uploadImage) {
  //     setUploadedImage(uploadImage);
  //   }
  // }, [uploadImage]);

  useEffect(() => {
    if (inPaintImage) {
      setUploadedImage(inPaintImage);
    }
  }, [inPaintImage]);

  useEffect(() => {
    let tab = searchParams.get("tab")?.toUpperCase();
    switch (tab) {
      case "VIDEO":
        setActiveTab(AIImagesSidebarTabs.Text2Video);
        break;
      case "IMAGE":
      default:
        setActiveTab(AIImagesSidebarTabs.Text2Image);
        break;
    }

    setIsOpenVisualizePopup(searchParams.get("action")?.toUpperCase() === "VISUALIZE");
  }, [location]);

  return (
    <>
      <Wrapper>
        <Content>
          <DashboardLayout
            withThemeSwitcher={false}
            childrenClassName="ai-images"
            startAdornment={
              <Link to="/my-projects?filter=ai-images">
                <Button
                  className="btn-back"
                  buttonTheme={ButtonThemes.Secondary}
                  icon={<img src="/images/arrow-left.svg" />}
                  text="Back"
                />
              </Link>
            }
          >
            <Visualize isVisualize={isOpenVisualizePopup} />
            <Row>
              <AIImagesLeftSidebar />
              <AIImagesContent />
            </Row>
          </DashboardLayout>
        </Content>
        <AIImagesRightSidebar />
      </Wrapper>
      <ChatTrigger />
      <ChatPopup />
    </>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: #191b1f;
  padding: 24px 18px 24px 24px;
  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  background: #f0f0f3;
  width: 100%;
  border-radius: 32px;

  & .navigation-content {
    display: none;
  }
  & > div:first-child {
    padding: 24px 40px 0px 40px;
  }
`;

const Row = styled.div`
  display: flex;
  column-gap: 28px;
  margin: 20px 0px 24px 0px;
  height: calc(100% - 146px);
`;

export default withPrivateRoute(AIImagesPage);
