import { FaceSwapType, FaceswapModules } from "../../types/faceSwapType";
import { Zone } from "../../types/project";

const FACESWAP = "FACESWAP_";

export const UPDATE_FACESWAP_LOADING = FACESWAP + "UPDATE_FACESWAP_LOADING";
export const UPLOAD_FILE_SERVER = FACESWAP + "UPLOAD_FILE_SERVER";
export const CREATE_FACESWAP_SERVER = FACESWAP + "CREATE_FACESWAP_SERVER";
export const GET_FACESWAP_LIST_SERVER = FACESWAP + "GET_FACESWAP_LIST_SERVER";
export const GET_FACESWAP_SERVER = FACESWAP + "GET_FACESWAP_SERVER";
export const CLEAR_FACESWAP = FACESWAP + "CLEAR_FACESWAP";
export const CLEAR_IMAGE_BY_TYPE = FACESWAP + "CLEAR_IMAGE_BY_TYPE";
export const ADD_MEDIA_TO_TARGET = FACESWAP + "ADD_MEDIA_TO_TARGET";
export const ADD_MEDIA_TO_SOURCE = FACESWAP + "ADD_MEDIA_TO_SOURCE";
export const CREATE_AI_SERVICE_SERVER = FACESWAP + "CREATE_AI_SERVICE_SERVER";
export const GET_AI_SERVICE_BY_ID_SERVER = FACESWAP + "GET_AI_SERVICE_BY_ID_SERVER";
export const GET_AI_SERVICE_LIST_SERVER = FACESWAP + "GET_AI_SERVICE_LIST_SERVER";
export const GENERATE_AUDIO_SERVER = FACESWAP + "GENERATE_AUDIO_SERVER";
export const SET_TEXT_FOR_AUDIO = FACESWAP + "SET_TEXT_FOR_AUDIO";
export const SET_VOICE_SELECT = FACESWAP + "SET_VOICE_SELECT";
export const GET_FACESWAP_IMAGE_FROM_TEXT_SERVER = FACESWAP + "GET_FACESWAP_IMAGE_FROM_TEXT_SERVER";
export const SET_TEXT_FOR_IMAGE = FACESWAP + "SET_TEXT_FOR_IMAGE";
export const SET_PERCENT = FACESWAP + "SET_PERCENT";
export const DELETE_FACESWAP_PROJECT_SERVER = FACESWAP + "DELETE_FACESWAP_PROJECT_SERVER";
export const DELETE_AI_SERVICE_PROJECT_SERVER = FACESWAP + "DELETE_AI_SERVICE_PROJECT_SERVER";
export const CLEAR_FACESWAP_LIST = FACESWAP + "CLEAR_FACESWAP_LIST";

interface UpdateFaceswapLoadingProps {
  module: FaceswapModules;
  isLoading: boolean;
}

interface FaceswapUploadFileServerProps {
  data: FormData;
  type: "target" | "source";
}

interface CreateFaceswapServerProps {
  faceswapType: FaceSwapType;
  source: string;
  target: string;
}

interface GetFaceswapListServerProps {
  pageNumber?: number;
  pageSize?: number;
  isLoading?: boolean;
}

interface GetFaceswapServerProps {
  id: number;
  isProjectRestored?: boolean;
}

interface CreateAIServiceServerProps {
  serviceTypeID: number;
  image?: string;
  video?: string;
  audio?: string;
  prompt?: string;
}

interface GetAIServiceListServerProps {
  pageNumber?: number;
  pageSize?: number;
  serviceTypeID: number;
  isLoading?: boolean;
}

interface GenerateAudioServerProps {
  data: Zone[];
}

export const updateFaceswapLoading = ({ module, isLoading }: UpdateFaceswapLoadingProps) => ({
  type: UPDATE_FACESWAP_LOADING,
  payload: {
    module,
    isLoading,
  },
});

export const faceswapUploadFileServer = ({ data, type }: FaceswapUploadFileServerProps) => ({
  type: UPLOAD_FILE_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/extension/upload_file",
      data,
    },
    type,
  },
});

export const createFaceswapServer = ({ faceswapType, source, target }: CreateFaceswapServerProps) => ({
  type: CREATE_FACESWAP_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/Faceswap/create",
      data: { faceswapType, source, target },
    },
  },
});

export const getFaceswapListServer = ({ pageNumber, pageSize, isLoading }: GetFaceswapListServerProps) => ({
  type: GET_FACESWAP_LIST_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/Faceswap/list",
      data: {
        pageNumber: pageNumber || 1,
        pageSize: pageSize || 10,
        keyword: "",
      },
    },
    isLoading,
  },
});

export const getFaceswapServer = ({ id, isProjectRestored }: GetFaceswapServerProps) => ({
  type: GET_FACESWAP_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/Faceswap/get`,
      params: { Id: id },
    },
    isProjectRestored,
  },
});

export const clearFaceswap = () => ({
  type: CLEAR_FACESWAP,
});

export const clearImageByType = (type: string) => ({
  type: CLEAR_IMAGE_BY_TYPE,
  payload: { type },
});

export const addMediaToTarget = (url: string) => ({
  type: ADD_MEDIA_TO_TARGET,
  payload: { url },
});

export const addMediaToSource = (url: string) => ({
  type: ADD_MEDIA_TO_SOURCE,
  payload: { url },
});

export const createAIServiceServer = ({ serviceTypeID, image, video, audio, prompt }: CreateAIServiceServerProps) => ({
  type: CREATE_AI_SERVICE_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/AIService/create",
      data: { serviceTypeID, input: { image, video, audio, prompt } },
    },
  },
});

export const getAIServiceByIdServer = ({ id, isProjectRestored }: GetFaceswapServerProps) => ({
  type: GET_AI_SERVICE_BY_ID_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/AIService/get?Id=${id}`,
    },
    isProjectRestored,
  },
});

export const getAIServiceListServer = ({
  serviceTypeID,
  pageNumber,
  pageSize,
  isLoading,
}: GetAIServiceListServerProps) => ({
  type: GET_AI_SERVICE_LIST_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/AIService/list",
      data: {
        pageNumber: pageNumber || 1,
        pageSize: pageSize || 10,
        keyword: "",
        serviceTypeID,
      },
    },
    isLoading,
  },
});

export const generateAudioServer = ({ data }: GenerateAudioServerProps) => ({
  type: GENERATE_AUDIO_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/actor/generateVoice",
      data: { data, IsAIHuman: true },
    },
  },
});

export const setTextForAudio = (text: string) => ({
  type: SET_TEXT_FOR_AUDIO,
  payload: { text },
});

export const setVoiceSelect = (actorid: string | number) => ({
  type: SET_VOICE_SELECT,
  payload: { actorid },
});

export const getFaceswapImageFromTextServer = (text: string) => ({
  type: GET_FACESWAP_IMAGE_FROM_TEXT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/AIImages/GetImageFromText",
      data: {
        textInput:
          text +
          ", portrait, close-up portrait the background is blurred, ensuring full focus on full face and head, capturing the essence of personality and grace",
        wd: 512,
        ht: 512,
        n_samples: 1,
      },
    },
  },
});

export const setTextForImage = (text: string) => ({
  type: SET_TEXT_FOR_IMAGE,
  payload: { text },
});

export const setPercent = (percent: number, type: string, projectId: number) => ({
  type: SET_PERCENT,
  payload: { percent, type, projectId },
});

export const deleteFaceswapProjectServer = ({ id }: { id: number }) => ({
  type: DELETE_FACESWAP_PROJECT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/Faceswap/delete?id=${id}`,
    },
  },
});

export const deleteAIServiceProjectServer = ({ id, typeId }: { id: number; typeId: number }) => ({
  type: DELETE_AI_SERVICE_PROJECT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/AIService/delete?id=${id}`,
    },
    typeId,
  },
});

export const clearFaceswapList = () => ({
  type: CLEAR_FACESWAP_LIST,
});
