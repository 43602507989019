import { CategoryProps } from "../types/filter";
import { countries } from "./countries";
import { languages } from "./languages";

export const categories = [
  {
    id: "8",
    type: CategoryProps.CATEGORY_TYPE,
    title: "Voice Type",
    data: [
      {
        id: "29",
        label: "Premium",
      },
      {
        id: "30",
        label: "Ultra Lifelike",
        icon: <img src="/images/actors/ultra-life-like.svg" />,
      },
      {
        id: "31",
        label: "Ultra Lifelike Turbo",
        icon: <img src="/images/actors/ultra-life-like.svg" />,
      },
      {
        id: "32",
        label: "Custom",
      },
      {
        id: "33",
        label: "Personalized voice styles",
      },
    ],
  },
  {
    id: "1",
    type: CategoryProps.LANGUAGE,
    title: "Language",
    data: languages,
  },
  {
    id: "2",
    type: CategoryProps.IS_FEMALE,
    title: "Gender",
    data: [
      {
        id: "Male",
        label: "Male",
      },
      {
        id: "Female",
        label: "Female",
      },
    ],
  },
  {
    id: "3",
    type: CategoryProps.VOICE_AGE,
    title: "Voice Age",
    data: [
      {
        id: "4",
        label: "Youth",
      },
      {
        id: "5",
        label: "Middle Aged",
      },
      {
        id: "6",
        label: "Elder",
      },
    ],
  },
  {
    id: "4",
    type: CategoryProps.MOOD,
    title: "Mood",
    data: [
      {
        id: "7",
        label: "Playful",
      },
      {
        id: "8",
        label: "Warm",
      },
      {
        id: "9",
        label: "Mature",
      },
      {
        id: "10",
        label: "Powerful",
      },
      {
        id: "11",
        label: "Expressive",
      },
      {
        id: "12",
        label: "Soft",
      },
      {
        id: "13",
        label: "Gentle",
      },
      {
        id: "14",
        label: "Confident",
      },
      {
        id: "15",
        label: "Classy",
      },
      {
        id: "16",
        label: "Trustful",
      },
      {
        id: "17",
        label: "Emotional",
      },
      {
        id: "18",
        label: "Calm",
      },
    ],
  },
  {
    id: "5",
    type: CategoryProps.CONTENT,
    title: "Content",
    data: [
      {
        id: "19",
        label: "Educational/Tutorials",
      },
      {
        id: "20",
        label: "Narration",
      },
      {
        id: "21",
        label: "Commercials",
      },
      {
        id: "22",
        label: "Sports/e-games",
      },
      {
        id: "23",
        label: "Audiobooks",
      },
      {
        id: "24",
        label: "News",
      },
      {
        id: "25",
        label: "Marketing",
      },
      {
        id: "27",
        label: "Reviews",
      },
      {
        id: "28",
        label: "Radio/Podcast",
      },
    ],
  },
];

export const chips = [
  { id: 1, title: "English" },
  { id: 2, title: "Trial" },
  { id: 3, title: "Angry" },
  { id: 4, title: "Old" },
];

export const characterCards = [
  {
    id: "1",
    flag: "/images/flag.png",
    name: "Wilson A.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
  },
  {
    id: "2",
    flag: "/images/flag.png",
    name: "Wilson A.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
  },
  {
    id: "3",
    flag: "/images/flag.png",
    name: "Wilson A.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
  },
  {
    id: "4",
    flag: "/images/flag.png",
    name: "Wilson A.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
  },
  {
    id: "5",
    flag: "/images/flag.png",
    name: "Wilson A.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
  },
  {
    id: "6",
    flag: "/images/flag.png",
    name: "Wilson A.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
  },
  {
    id: "7",
    flag: "/images/flag.png",
    name: "Wilson A.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
  },
  {
    id: "8",
    flag: "/images/flag.png",
    name: "Wilson A.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
  },
  {
    id: "9",
    flag: "/images/flag.png",
    name: "Wilson A.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
  },
  {
    id: "10",
    flag: "/images/flag.png",
    name: "Wilson A.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
  },
  {
    id: "11",
    flag: "/images/flag.png",
    name: "Wilson A.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
  },
  {
    id: "12",
    flag: "/images/flag.png",
    name: "Wilson A.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
  },
  {
    id: "13",
    flag: "/images/flag.png",
    name: "Wilson A.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
  },
  {
    id: "14",
    flag: "/images/flag.png",
    name: "Wilson A.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
  },
  {
    id: "15",
    flag: "/images/flag.png",
    name: "Wilson A.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
  },
];

export const categoriesHumans = [
  // {
  //   id: "1",
  //   type: CategoryProps.REGION,
  //   title: "Regions",
  //   data: [
  //     {
  //       id: "Africa",
  //       label: "Africa",
  //     },
  //     {
  //       id: "Asia",
  //       label: "Asia",
  //     },
  //     {
  //       id: "Australia",
  //       label: "Australia",
  //     },
  //     {
  //       id: "Europe",
  //       label: "Europe",
  //     },
  //     {
  //       id: "Middle East",
  //       label: "Middle East",
  //     },
  //     {
  //       id: "North America",
  //       label: "North America",
  //     },
  //     {
  //       id: "South America",
  //       label: "South America",
  //     },
  //   ],
  // },
  {
    id: "2",
    type: CategoryProps.IS_FEMALE,
    title: "Gender",
    data: [
      {
        id: "Male",
        label: "Male",
      },
      {
        id: "Female",
        label: "Female",
      },
    ],
  },
  {
    id: "3",
    type: CategoryProps.VOICE_AGE,
    title: "Age",
    data: [
      {
        id: "4",
        label: "Youth",
      },
      {
        id: "5",
        label: "Middle Aged",
      },
      {
        id: "6",
        label: "Elder",
      },
    ],
  },
  {
    id: "8",
    type: CategoryProps.CATEGORY,
    title: "Category",
    data: [
      {
        id: "31",
        label: "Humatars",
      },
      {
        id: "32",
        label: "Custom",
      },
      {
        id: "33",
        label: "Seated",
      },
    ],
  },
];
