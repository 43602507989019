import { Store } from "redux";
import { StoreType } from "../../types/store";
import { Popups, UPDATE_POPUP } from "../actions/popupsActions";

export interface popupsStateType {
  popups: {
    [key in Popups]: boolean;
  };
  prefilled: any;
}

export const popupsInitialState: popupsStateType = {
  popups: {
    [Popups.createNewProjectPopup]: false,
    [Popups.estimatedPopup]: false,
    [Popups.characterPopup]: false,
    [Popups.aIHumansPopup]: false,
    [Popups.templatesPopup]: false,
    [Popups.generativeVideo]: false,
    [Popups.shareVideoLinkPopup]: false,
    [Popups.confirmationPopup]: false,
    [Popups.chatPopup]: false,
    [Popups.vimeoPopup]: false,
    [Popups.historyPopup]: false,
    [Popups.myLibraryPopup]: false,
    [Popups.addVoiceAudioPopup]: false,
    [Popups.addVoiceAudioInfoPopup]: false,
    [Popups.aIHumansPreviewPopup]: false,
    [Popups.signUpPopup]: false,
    [Popups.enlargedImagePopup]: false,
    [Popups.text2VideoPopup]: false,
    [Popups.uploadAIImageAssetPopup]: false,
    [Popups.trimImagePopup]: false,
    [Popups.aIImageRemoveAreaPopup]: false,
    [Popups.cropImagePopup]: false,
    [Popups.notEnoughCreditsPopup]: false,
    [Popups.cancelSubscriptionPopup]: false,
    [Popups.subscriptionCanceledPopup]: false,
    [Popups.changeSubscriptionPopup]: false,
    [Popups.paymentMethodPKSPopup]: false,
    [Popups.changedSubscriptionPopup]: false,
    [Popups.deleteAccountPopup]: false,
    [Popups.addCustomAvatarPopup]: false,
    [Popups.selectTypeCustomAvatarPopup]: false,
    [Popups.embedOnWebsitePopup]: false,
    [Popups.aiVoicesText2SpeechReplaceProjectPopup]: false,
    [Popups.faceswapReplaceProjectPopup]: false,
  },
  prefilled: {},
};

const popupsReducer = (state = popupsInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_POPUP: {
      const { popup, status, prefilled } = action.payload;
      return {
        ...state,
        prefilled,
        popups: {
          ...state.popups,
          [popup]: status,
        },
      };
    }
    default:
      return { ...state };
  }
};

export const getCreateNewProjectPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.createNewProjectPopup];
export const getEstimatedPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.estimatedPopup];
export const getCharacterPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.characterPopup];
export const getAIHumansPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.aIHumansPopup];
export const getTemplatePopupIsOpen = (state: StoreType) => state.popups.popups[Popups.templatesPopup];
export const getGenerativeVideoPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.generativeVideo];
export const getShareVideoLinkPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.shareVideoLinkPopup];
export const getConfirmationPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.confirmationPopup];
export const getChatPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.chatPopup];
export const getVimeoPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.vimeoPopup];
export const getHistoryPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.historyPopup];
export const getMyLibraryPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.myLibraryPopup];
export const getAddVoiceAudioPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.addVoiceAudioPopup];
export const getAddVoiceAudioInfoPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.addVoiceAudioInfoPopup];
export const getAIHumansPreviewPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.aIHumansPreviewPopup];
export const getSignUpPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.signUpPopup];
export const getEnlargedImagePopupOpen = (state: StoreType) => state.popups.popups[Popups.enlargedImagePopup];
export const getText2VideoPopupOpen = (state: StoreType) => state.popups.popups[Popups.text2VideoPopup];
export const getUploadAIImageAssetPopupOpen = (state: StoreType) => state.popups.popups[Popups.uploadAIImageAssetPopup];
export const getTrimImagePopupOpen = (state: StoreType) => state.popups.popups[Popups.trimImagePopup];
export const getAIImageRemoveAreaPopupOpen = (state: StoreType) => state.popups.popups[Popups.aIImageRemoveAreaPopup];
export const getCropImagePopupIsOpen = (state: StoreType) => state.popups.popups[Popups.cropImagePopup];
export const getNotEnoughCreditsPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.notEnoughCreditsPopup];
export const getCancelSubscriptionPopupIsOpen = (state: StoreType) =>
  state.popups.popups[Popups.cancelSubscriptionPopup];
export const getSubscriptionCanceledPopupIsOpen = (state: StoreType) =>
  state.popups.popups[Popups.subscriptionCanceledPopup];
export const getChangeSubscriptionPopupIsOpen = (state: StoreType) =>
  state.popups.popups[Popups.changeSubscriptionPopup];
export const getPaymentMethodPKSPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.paymentMethodPKSPopup];
export const getChangedSubscriptionPopupIsOpen = (state: StoreType) =>
  state.popups.popups[Popups.changedSubscriptionPopup];
export const getDeleteAccountPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.deleteAccountPopup];
export const getAddCustomAvatarPopupIsOpen = (state: StoreType) => state.popups.popups[Popups.addCustomAvatarPopup];
export const getSelectTypeCustomAvatarPopup = (state: StoreType) =>
  state.popups.popups[Popups.selectTypeCustomAvatarPopup];
export const getAIVoicesText2SpeechReplaceProjectPopupIsOpen = (state: StoreType) =>
  state.popups.popups[Popups.aiVoicesText2SpeechReplaceProjectPopup];
export const getFaceswapReplaceProjectPopupIsOpen = (state: StoreType) =>
  state.popups.popups[Popups.faceswapReplaceProjectPopup];

export const getEmbedOnWebsitePopup = (state: StoreType) => state.popups.popups[Popups.embedOnWebsitePopup];
export const getPrefilled = (state: StoreType) => state.popups.prefilled;

export default popupsReducer;
