import { ProfileModules } from "../../types/profile";
import { UserHistoryProjectPrefilled } from "../../types/userHistoryProjects";

export const GET_MY_PROFILE_SERVER = "GET_MY_PROFILE_SERVER";
export const CHANGE_PASSWORD_SERVER = "CHANGE_PASSWORD_SERVER";
export const UPDATE_PROFILE_LOADING = "UPDATE_PROFILE_LOADING";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SERVER = "UPDATE_PROFILE_SERVER";
export const UPLOAD_PROFILE_PIC_SERVER = "UPLOAD_PROFILE_PIC_SERVER";
export const UPLOAD_PROFILE_PIC = "UPLOAD_PROFILE_PIC";
export const DELETE_PROFILE_PIC_SERVER = "DELETE_PROFILE_PIC_SERVER";
export const CLONE_VOICE_SERVER = "CLONE_VOICE_SERVER";
export const GENERATE_API_KEY_SERVER = "GENERATE_API_KEY_SERVER";
export const REVOKE_API_KEY_SERVER = "REVOKE_API_KEY_SERVER";
export const DELETE_ACCOUNT_SERVER = "DELETE_ACCOUNT_SERVER";
export const GET_USER_HISTORY_PROJECTS_SERVER = "GET_USER_HISTORY_PROJECTS_SERVER";
export const GET_USER_HISTORY_PROJECTS_INFINITE_SERVER = "GET_USER_HISTORY_PROJECTS_INFINITE_SERVER";
export const UPDATE_HAS_MORE_USER_HISTORY_PROJECTS = "UPDATE_HAS_MORE_USER_HISTORY_PROJECTS";
export const SET_USER_HISTORY_PROJECTS_PAGE = "SET_USER_HISTORY_PROJECTS_PAGE";
export const CLEAR_USER_HISTORY_PROJECTS = "CLEAR_USER_HISTORY_PROJECTS";
export const UPDATE_HISTORY_PROJECT_SERVER = "UPDATE_HISTORY_PROJECT_SERVER";
export const DELETE_HISTORY_PROJECT_SERVER = "DELETE_HISTORY_PROJECT_SERVER";
export const FAVORITE_HISTORY_PROJECT_SERVER = "FAVORITE_HISTORY_PROJECT_SERVER";

interface UpdateProfileLoadingProps {
  module: ProfileModules;
  isLoading: boolean;
}

interface ChangePasswordSeverProps {
  oldPassword: string;
  newPassword: string;
}

interface UpdateProfileServerProps {
  country?: string;
  language?: string;
  timePreference?: string;
  name?: string;
  email?: string;
  contactNo?: string;
  receiveUpdates?: boolean;
  profilePic?: string;
}

interface GetMyProfileServerProprs {
  checkCloneVoice?: boolean;
  checkCustomAvatar?: boolean;
}

interface GetUserHistoryProjectsServerProps {
  pageNumber: number;
  pageSize: number;
  category?: string;
  mediaType?: string;
  sortByDesc?: boolean;
}

interface SetUserHistoryProjectsPage {
  pageNumber: number;
}

interface UpdateHasMoreUserHistoryProjectsProps {
  hasMore: boolean;
}

interface UpdateHistoryProjectServerProps {
  id: number;
  category: string;
  newTitle: string;
  oldTitle: string;
  isHumanProject?: boolean;
}

interface DeleteHistoryProjectServerProps {
  id: number;
  category: string;
  isDeleted: true;
  pageSize: number;
  option: string;
  previousFill?: UserHistoryProjectPrefilled;
}

interface FavoriteHistoryProjectServerProps {
  id: number;
  category: string;
  newFavorite: boolean | null;
  oldFavorite: boolean | null;
}

export const getMyProfileServer = ({ checkCloneVoice, checkCustomAvatar }: GetMyProfileServerProprs = {}) => ({
  type: GET_MY_PROFILE_SERVER,
  payload: {
    request: {
      method: "GET",
      url: "/user/profile",
    },
    checkCloneVoice: checkCloneVoice || false,
    checkCustomAvatar: checkCustomAvatar || false,
  },
});

export const updateProfile = (data: UpdateProfileServerProps) => ({
  type: UPDATE_PROFILE,
  payload: data,
});

export const updateProfileServer = (data: UpdateProfileServerProps) => ({
  type: UPDATE_PROFILE_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/user/updateSettings",
      data,
    },
  },
});

export const uploadProfilePic = (formData: FormData) => ({
  type: UPLOAD_PROFILE_PIC,
  payload: formData,
});

export const uploadProfilePicServer = (formData: FormData) => ({
  type: UPLOAD_PROFILE_PIC_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/user/uploadProfilePic",
      data: formData,
    },
  },
});

export const deleteProfilePicServer = () => ({
  type: DELETE_PROFILE_PIC_SERVER,
  payload: {
    request: {
      method: "DELETE",
      url: "/user/deleteProfilePic",
    },
  },
});

export const changePasswordServer = (data: ChangePasswordSeverProps) => ({
  type: CHANGE_PASSWORD_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/user/changePassword`,
      data,
    },
  },
});

export const updateProfileLoading = ({ module, isLoading }: UpdateProfileLoadingProps) => ({
  type: UPDATE_PROFILE_LOADING,
  payload: {
    module,
    isLoading,
  },
});

export const cloneVoiceServer = (data: FormData, name: string, isChatGpt: boolean) => ({
  type: CLONE_VOICE_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/user/cloneVoice",
      data,
    },
    name,
    isChatGpt,
  },
});

export const generateApiKeyServer = () => ({
  type: GENERATE_API_KEY_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/user/generateApiKey",
    },
  },
});

export const revokeApiKeyServer = () => ({
  type: REVOKE_API_KEY_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/user/generateApiKey?revoke=true",
    },
  },
});

export const deleteAccountServer = () => ({
  type: DELETE_ACCOUNT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/user/deleteCompleteData",
    },
  },
});

export const getUserHistoryProjectsServer = ({
  pageNumber,
  pageSize,
  category,
}: GetUserHistoryProjectsServerProps) => ({
  type: GET_USER_HISTORY_PROJECTS_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/user/history",
      data: {
        pageNumber,
        pageSize,
        category,
        sortWith: "insertDateTime",
        sortByDesc: true,
      },
    },
  },
});

export const getUserHistoryProjectsInfiniteServer = ({
  pageNumber,
  pageSize,
  category,
  mediaType,
  sortByDesc = true,
}: GetUserHistoryProjectsServerProps) => ({
  type: GET_USER_HISTORY_PROJECTS_INFINITE_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/user/history",
      data: {
        pageNumber,
        pageSize,
        category,
        sortWith: "insertDateTime",
        sortByDesc,
        type: mediaType,
      },
    },
  },
});

export const updateHasMoreUserHistoryProjects = ({ hasMore }: UpdateHasMoreUserHistoryProjectsProps) => ({
  type: UPDATE_HAS_MORE_USER_HISTORY_PROJECTS,
  payload: {
    hasMore,
  },
});

export const setUserHistoryProjectsPage = ({ pageNumber }: SetUserHistoryProjectsPage) => ({
  type: SET_USER_HISTORY_PROJECTS_PAGE,
  payload: { pageNumber },
});

export const clearUserHistoryProjects = () => ({
  type: CLEAR_USER_HISTORY_PROJECTS,
});

export const updateHistoryProjectServer = ({
  id,
  category,
  newTitle,
  oldTitle,
  isHumanProject,
}: UpdateHistoryProjectServerProps) => ({
  type: UPDATE_HISTORY_PROJECT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/user/updateHistory",
      data: { id, category, title: newTitle },
    },
    oldTitle,
    isHumanProject,
  },
});

export const deleteHistoryProjectServer = ({
  id,
  category,
  isDeleted,
  pageSize,
  option,
  previousFill,
}: DeleteHistoryProjectServerProps) => ({
  type: DELETE_HISTORY_PROJECT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/user/updateHistory",
      data: { id, category, isDeleted },
    },
    pageSize,
    option,
    previousFill,
  },
});

export const favoriteHistoryProjectServer = ({
  id,
  category,
  newFavorite,
  oldFavorite,
}: FavoriteHistoryProjectServerProps) => ({
  type: FAVORITE_HISTORY_PROJECT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/user/updateHistory",
      data: { id, category, favorite: newFavorite },
    },
    oldFavorite,
  },
});
