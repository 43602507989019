import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import NavbarCard from "./components/navbarCard";
import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarLayout from "../../layouts/SidebarLayout";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import HomeSection from "./components/homeSection/HomeSection";
import AIVoicesCard from "./components/homeSection/sections/AIVoices/AIVoicesCard";
import AIHumansCard from "./components/homeSection/sections/AIVoices/AIHumansCard";
import { MyStudioContent } from "../MyStudio";
import { useMyStudio } from "../../hooks/useMyStudio";
import { TrainingTab } from "../Training/TrainingPage";
import { getOauth } from "../../redux/reducers/authReducer";
import { NotEnoughCredits } from "../../types/notEnoughCredits";
import { getProfile } from "../../redux/reducers/profileReducer";
import { HomeNavbarTabs } from "./components/navbarCard/NavbarTabs";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { getMyProfileServer } from "../../redux/actions/profileActions";
import { clearActors, getActorsServer } from "../../redux/actions/actorActions";
import { CustomAvatar } from "../../components/Popups/SelectTypeCustomAvatarPopup";
import { clearHumatars, getHumatarsServer } from "../../redux/actions/humatarActions";
import { getActorsList, getActorsListLoading } from "../../redux/reducers/actorReducer";
import { TrainingCards } from "./components/homeSection/sections/training/TrainingCards";
import { getHumatarList, getHumatarListLoading } from "../../redux/reducers/humatarReducer";
import { AIHumansData, AIVoicesData, FeatureToolsData, SynthesysLabData } from "../../mocks/home/navbarCardMocks";
import { AIVoicesTabs, ProjectsDisplayMode, TrainingTabs, projectDisplayModeTabs } from "../../mocks/home/homeTabs";
import { getRedirectToProject } from "../../redux/reducers/projectReducer";
import { redirectToProject } from "../../redux/actions/projectAction";

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actors = useSelector(getActorsList);
  const actorsLoading = useSelector(getActorsListLoading);
  const humatars = useSelector(getHumatarList);
  const humatarsLoading = useSelector(getHumatarListLoading);
  const projectRedirectUrl = useSelector(getRedirectToProject);
  const { redirect_uri, state } = useSelector(getOauth);
  const { voiceCloneAllowed = 0, allowToCloneAIHuman, voiceCloneUsed = 0 } = useSelector(getProfile);

  const {
    sortByDesc,
    featuresList: { list, isLoading, hasMore, currentPage, isSort },
    isStatusLoading,
    activeProject,
    activeProjectTypeId,
    handleActiveProject,
    getProjectListFunc,
    getAIImagesFunc,
    getGalleryVideosFunc,
    setActiveProject,
    handleActiveDisplayMode,
    activeDisplayMode,
  } = useMyStudio({ projectDisplayModeTabs });

  const [searchParams] = useSearchParams();
  const [trainingActiveTab, setTrainingActiveTab] = useState(TrainingTabs[0].value);
  const [AIVoicesActiveTab, setAIVoicesActiveTab] = useState(AIVoicesTabs[0].value);

  const handleTrainingActiveTab = (tab: string) => {
    const params = new URLSearchParams(location.search);
    params.set("training-filter", tab);
    navigate(`?${params.toString()}`);
    setTrainingActiveTab(tab);
  };

  const handleAIVoicesActiveTab = (tab: string) => setAIVoicesActiveTab(tab);

  const upgradeYourPlan = (link?: string) => {
    const prefilled: NotEnoughCredits = {
      title: "Upgrade your plan to access this feature",
      description: "Don't worry, you can easily upgrade your plan to get more credits and access additional features.",
      link,
    };

    dispatch(
      updatePopup({
        popup: Popups.notEnoughCreditsPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const handleAddInstantHumatar = () => {
    dispatch(
      updatePopup({
        popup: Popups.selectTypeCustomAvatarPopup,
        status: true,
        prefilled: { noRefresh: true, prefilledType: CustomAvatar.InstantHumatar },
      }),
    );
  };

  const handleAddTalkingImages = () => {
    dispatch(
      updatePopup({
        popup: Popups.selectTypeCustomAvatarPopup,
        status: true,
        prefilled: { noRefresh: true, prefilledType: CustomAvatar.TalkingImage },
      }),
    );
  };

  useEffect(() => {
    dispatch(clearActors());
    dispatch(
      getActorsServer({
        keyword: "",
        pageNumber: 1,
        categoryType: [],
        voiceAge: [],
        isFeMale: null,
        mood: [],
        content: [],
        region: [],
        language: [],
        country: [],
        popular: true,
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(clearHumatars());
    dispatch(
      getHumatarsServer({
        keyword: "",
        pageNumber: 1,
        categoryType: [],
        voiceAge: [],
        isFeMale: null,
        region: [],
        popular: true,
        category: ["Custom", "Seated", "Humatars"],
      }),
    );
  }, []);

  useEffect(() => {
    const cloneParam = searchParams.get("clone");
    const customAvatarParam = searchParams.get("avatar");

    if (cloneParam?.toLowerCase() === "true") {
      dispatch(updatePopup({ popup: Popups.addVoiceAudioPopup, status: true }));
      return;
    }
    if (customAvatarParam?.toLowerCase() === "talkingavatar") {
      searchParams.delete("avatar");
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
      dispatch(
        updatePopup({
          popup: Popups.addCustomAvatarPopup,
          status: true,
          prefilled: { noRefresh: true, type: CustomAvatar.TalkingImage },
        }),
      );
      return;
    }
  }, [searchParams]);

  useEffect(() => {
    if (redirect_uri && state) {
      dispatch(updatePopup({ popup: Popups.addVoiceAudioPopup, status: true }));
    }
  }, []);

  const NavbarCardTabsContent = {
    [HomeNavbarTabs.FeaturedTools]: FeatureToolsData.map((item) =>
      item.title === "Cloning Voice"
        ? {
            ...item,
            onClick: () =>
              voiceCloneAllowed === 0 || voiceCloneAllowed <= voiceCloneUsed
                ? upgradeYourPlan()
                : dispatch(getMyProfileServer({ checkCloneVoice: true })),
          }
        : { ...item },
    ),
    [HomeNavbarTabs.AIVoices]: AIVoicesData.map((item) =>
      item.title === "Multilingual Voice Cloning"
        ? {
            ...item,
            onClick: () =>
              voiceCloneAllowed === 0 || voiceCloneAllowed <= voiceCloneUsed
                ? upgradeYourPlan()
                : dispatch(getMyProfileServer({ checkCloneVoice: true })),
          }
        : { ...item },
    ),
    [HomeNavbarTabs.AIHumans]: AIHumansData.map((item) => {
      if (item.title === "Talking Images") {
        return { ...item, onClick: () => handleAddTalkingImages() };
      } else if (item.title === "Instant avatars") {
        return { ...item, onClick: () => handleAddInstantHumatar() };
      } else if (item.title === "Custom Studio Avatars") {
        if (allowToCloneAIHuman) {
          return { ...item };
        } else {
          return { ...item, isDisabled: true, onClick: () => upgradeYourPlan("https://synthesys.io/custom-avatar/") };
        }
      } else {
        return { ...item };
      }
    }),
    [HomeNavbarTabs.SynthesysLab]: SynthesysLabData.map((item) => ({
      ...item,
    })),
    [HomeNavbarTabs.AIWorkflows]: SynthesysLabData.map((item) => ({
      ...item,
    })),
  };

  const trainingSectionContent: { [key: string]: React.ReactElement } = {
    "AI Voices": <TrainingCards tabValue={TrainingTab.voices} />,
    "AI Humans": <TrainingCards tabValue={TrainingTab.humans} />,
    "AI Images": <TrainingCards tabValue={TrainingTab.images} />,
    "Video Translate": <TrainingCards tabValue={TrainingTab.translate} />,
  };

  const AIVoicesSectionContent: { [key: string]: React.ReactElement } = {
    "AI Voices": <AIVoicesCard data={actors} loading={actorsLoading} />,
    "AI Humans": <AIHumansCard data={humatars} loading={humatarsLoading} />,
  };

  const projectLinks = {
    [ProjectsDisplayMode.aiHuman]: "ai-humans",
    [ProjectsDisplayMode.aiVoices]: "actors",
    [ProjectsDisplayMode.aiImages]: "ai-images",
    [ProjectsDisplayMode.aiVideos]: "ai-videos",
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterValue = params.get("training-filter") || "";

    if (projectRedirectUrl) {
      navigate(projectRedirectUrl);
      return () => {
        dispatch(redirectToProject({ projectName: "", projectId: 0 }));
      };
    }

    handleTrainingActiveTab(filterValue as any);
    if (!filterValue) {
      const params = new URLSearchParams(location.search);
      params.set("training-filter", "AI Voices");
      navigate(`?${params.toString()}`);
    }
  }, [location.href]);

  return (
    <Wrapper>
      <SidebarLayout>
        <Content>
          <MobileOnly>
            <Sidebar mobile />
          </MobileOnly>
          <NavbarCard TabContent={NavbarCardTabsContent} />
          <HomeSection
            tabs={TrainingTabs}
            content={trainingSectionContent[trainingActiveTab]}
            activeTab={trainingActiveTab}
            title="Training"
            link={{ to: `/training?training-filter=${trainingActiveTab}`, text: "View All" }}
            handleActiveTab={handleTrainingActiveTab}
          />
          <HomeSection
            tabs={AIVoicesTabs}
            content={AIVoicesSectionContent[AIVoicesActiveTab]}
            activeTab={AIVoicesActiveTab}
            title="Get Started"
            handleActiveTab={handleAIVoicesActiveTab}
            link={{
              to: `${AIVoicesActiveTab === "AI Voices" ? "/actors" : "/ai-humans"}`,
              text: "Create new project",
            }}
          />
          <HomeSection
            tabs={projectDisplayModeTabs}
            content={
              <MyStudioContentWrapper
                id="scrollableDiv"
                displayMode={activeDisplayMode}
                isLoading={isLoading && !list.length}
              >
                <MyStudioContent
                  {...{
                    sortByDesc,
                    selectedValue: activeDisplayMode,
                    isStatusLoading,
                    isSort,
                    getProjectListFunc,
                    currentPage,
                    activeProjectTypeId,
                    hasMore,
                    isLoading,
                    list,
                    activeProject,
                    setActiveProject,
                    handleActiveProject,
                    getAIImagesFunc,
                    getGalleryVideosFunc,
                    maxItems: 5,
                    isHomePage: true,
                  }}
                />
              </MyStudioContentWrapper>
            }
            activeTab={activeDisplayMode}
            title={"My Studio"}
            handleActiveTab={handleActiveDisplayMode as (props: string) => void}
            link={{ text: "View all", to: `/my-projects?filter=${projectLinks[activeDisplayMode]}` }}
          />
        </Content>
      </SidebarLayout>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;

  @media (max-width: 1001px) {
    & .navigation {
      visibility: unset;
      height: 88px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  flex: 1;
  gap: 47px;
  padding-right: 5px;
  padding-bottom: 20px;

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.homeContentScroll};
    border: 5px solid transparent;
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-track {
    margin: 17px 0;
  }
`;

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 1001px) {
    display: flex;

    & > div {
      display: flex;
    }
  }
`;

const MyStudioContentWrapper = styled("div")<{ displayMode: ProjectsDisplayMode; isLoading?: boolean }>`
  position: relative;
  display: flex;
  align-content: stretch;
  overflow: hidden auto;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  max-height: 100%;
  gap: ${({ displayMode }) => (displayMode === ProjectsDisplayMode.aiVoices ? "14px" : "12px")};
  min-height: ${({ displayMode }) =>
    displayMode === ProjectsDisplayMode.aiHuman
      ? "276px"
      : displayMode === ProjectsDisplayMode.aiVoices
      ? "202px"
      : "176px"};
  margin-top: 14px;
  padding: 8px 8px;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  .infinite-scroll-component {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }

  .project-card {
    width: 226px;
    background: ${({ theme }) => theme.voiceCardBackground};
    box-shadow: ${({ theme }) => theme.voiceCardShadow};
    &:hover {
      background: ${({ theme }) => theme.hoverVoiceCardBackground};
      & > div {
        div,
        span {
          color: white;
          svg > path {
            fill: white;
          }
        }
      }
    }
  }

  .project-card-large {
    width: 227px;
    // background: ${({ theme }) => theme.voiceCardBackground};
    // box-shadow: ${({ theme }) => theme.voiceCardShadow};
    &:hover {
      background: ${({ theme }) => theme.hoverVoiceCardBackground};
      & > :nth-child(2) {
        div {
          p {
            color: white;
          }
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }
  }

  ${({ isLoading }) =>
    isLoading &&
    `
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    `}

  @media (max-width: 1345px) {
    .project-card {
      width: 220px;
    }

    .project-card-large {
      width: 236px;
    }
  }

  @media (max-width: 550px) {
    display: grid;
    justify-content: space-between;
    grid-template-columns: ${({ displayMode }) =>
      displayMode === ProjectsDisplayMode.aiVoices
        ? "repeat(auto-fit, minmax(220px, 1fr))"
        : "repeat(auto-fit, minmax(236px, 1fr))"};
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 37px 0;
  }
`;

export default withPrivateRoute(HomePage);
