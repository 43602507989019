import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import MediaCard from "../MediaCard";
import VideoCard from "../VideoCard";
import SourceFaceCard from "../SourceFaceCard";
import { FaceswapProjectTab } from "../..";
import {
  clearFaceswap,
  getAIServiceByIdServer,
  getAIServiceListServer,
} from "../../../../redux/actions/faceswapActions";

const TransformScreen = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const fetchData = (isLoading?: boolean) => dispatch(getAIServiceListServer({ serviceTypeID: 1, isLoading }));

  useEffect(() => {
    fetchData(true);

    const intervalId = setInterval(() => fetchData(false), 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    dispatch(clearFaceswap());

    if (id) dispatch(getAIServiceByIdServer({ id: parseInt(id), isProjectRestored: true }));
  }, [id]);

  return (
    <>
      <SourceFaceCard cardTitle="Character" type={FaceswapProjectTab.Transform} tooltipType="image" />
      <MediaCard type={FaceswapProjectTab.Transform} cardTitle="Video" activeTab="Video" setActiveTab={() => null} />
      <VideoCard type={FaceswapProjectTab.Transform} generateButtonText="Generate video" activeTab="Video" />
    </>
  );
};

export default TransformScreen;
